
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponseBase } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HeaderService } from '../shared/header.service';
@Injectable()
export class DataManagerService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private headerSrv: HeaderService) { }

    addEditSeries(addEditSeriesData: { [key: string]: any }) {
        const body = new FormData();
        if (addEditSeriesData.n_series) {
            body.append('n_series', addEditSeriesData.n_series.toString())
        }
        if (addEditSeriesData.symbol) {
            body.append('symbol', addEditSeriesData.symbol)
        }
        if (addEditSeriesData.source) {
            body.append('source', addEditSeriesData.source)
        }
        if (Object.keys(addEditSeriesData.n_range).length > 0 && addEditSeriesData.n_range.constructor === Object) {
            body.append('n_range', addEditSeriesData.n_range.id.toString())
        }
        if (addEditSeriesData.website) {
            body.append('website', addEditSeriesData.website)
        }
        if (addEditSeriesData.datacomment) {
            body.append('datacomment', addEditSeriesData.datacomment)
        }
        if (addEditSeriesData.description) {
            body.append('description', addEditSeriesData.description)
        }
        body.append('shortname', addEditSeriesData.shortname) // *Required*
        body.append('longname', addEditSeriesData.longname) // *Required*
        body.append('frequency', addEditSeriesData.frequency.id.toString()) // *Required*
        body.append('units', addEditSeriesData.units.id.toString()) // *Required*
        body.append('unitsName', addEditSeriesData.units.name) // *Required*
        body.append('unitOther', addEditSeriesData.unitOther) // *Required*
        body.append('n_type', addEditSeriesData.type.id.toString()) // *Required*
        body.append('edit', addEditSeriesData.edit.toString()) // *Required* true or false
        body.append('active', addEditSeriesData.active.toString()) // *Required* true or false
        if (addEditSeriesData.edit === true) {
            const fnpArray = [
                { id: '1', name: 'about' },
                { id: '2', name: 'affect' },
                { id: '3', name: 'context' },
                { id: '4', name: 'country' },
                { id: '5', name: 'company' },
                { id: '6', name: 'keyword' },
                { id: '14', name: 'group' },
                { id: '90', name: 'region' },
                { id: '310', name: 'financialMetric' },
                { id: '471', name: 'financialCategory' },
            ]
            for (const key in addEditSeriesData.fingerprint) {
                if (addEditSeriesData.fingerprint.hasOwnProperty(key)) {
                    for (let i = 0; i < fnpArray.length; i++) {
                        if (key === fnpArray[i].id) {
                            // console.log(fnpArray[i].name, addEditSeriesData.fingerprint[key].map(x => x.id))
                            body.append(fnpArray[i].name, addEditSeriesData.fingerprint[key].map(x => x.id))
                            break;
                        };
                    }
                }
            }
        }
        if (addEditSeriesData.lastupdate === 'yes') {
            addEditSeriesData.lastupdate = true;
            body.append('lastupdate', addEditSeriesData.lastupdate.toString()) // *Required* true or false
        } else if (addEditSeriesData.lastupdate === 'no') {
            addEditSeriesData.lastupdate = false;
            body.append('lastupdate', addEditSeriesData.lastupdate.toString()) // *Required* true or false
        } else {
            body.append('lastupdate', addEditSeriesData.lastupdate.toString()) // *Required* true or false
        }
        return this.http.post(`${this.url}/datamanager/series`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaSearch(mTypeId: number, query: string) {
        const body = new FormData();
        body.append('term', query)
        body.append('metatype', mTypeId.toString());
        return this.http.post(`${this.url}/datamanager/getMetaSearch`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DataManSearch(searchParams: { active: boolean, type: {id: number, name: string}, searchFrom: number, searchSize:number,
        sortField:string, sortOrder: number, q: string, iMv: {id: number, name: string}[], iAmv: {id: number, name: string}[], oMV: {id: number, name: string}[] }) {
        const body = new FormData();
        body.append('active', searchParams.active.toString());
        body.append('type', searchParams.type.id.toString());
        body.append('searchFrom', searchParams.searchFrom.toString());
        body.append('searchSize', searchParams.searchSize.toString());
        body.append('sortField', searchParams.sortField);
        body.append('sortOrder', searchParams.sortOrder.toString());
        if (searchParams.iMv) {
            body.append('iMv', searchParams.iMv.map(value=>value.id).join());
        }
        if (searchParams.iAmv) {
            body.append('iAmv', searchParams.iAmv.map(value=>value.id).join());
        }
        if (searchParams.oMV) {
            body.append('oMV', searchParams.oMV.map(value=>value.id).join());
        }
        if (searchParams.q) {
            body.append('q', searchParams.q);
        }
        return this.http.post(`${this.url}/datamanager/search`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ExportDataManSearchSeries(searchParams: { active: boolean, type: {id: number, name: string}, searchFrom: number, searchSize:number,
        sortField:string, sortOrder: number, q: string, iMv: {id: number, name: string}[], iAmv: {id: number, name: string}[], oMV: {id: number, name: string}[], exportseries: boolean }) {
        const body = new FormData();
        body.append('active', searchParams.active.toString());
        body.append('type', searchParams.type.id.toString());
        body.append('searchFrom', searchParams.searchFrom.toString());
        body.append('searchSize', searchParams.searchSize.toString());
        body.append('sortField', searchParams.sortField);
        body.append('sortOrder', searchParams.sortOrder.toString());
        body.append('exportseries', searchParams.exportseries.toString());
        if (searchParams.iMv) {
            body.append('iMv', searchParams.iMv.map(value=>value.id).join());
        }
        if (searchParams.iAmv) {
            body.append('iAmv', searchParams.iAmv.map(value=>value.id).join());
        }
        if (searchParams.oMV) {
            body.append('oMV', searchParams.oMV.map(value=>value.id).join());
        }
        if (searchParams.q) {
            body.append('q', searchParams.q);
        }
        return this.http.post(`${this.url}/datamanager/search`, body, { headers: this.headerSrv.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    getDataSeriesById(seriesId: number) {
        const body = new FormData();
        body.append('series', seriesId.toString());
        return this.http.post(`${this.url}/datamanager/getDataSeries`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    searchRelationship(seriesId: number, query: string) {
        const body = new FormData();
        body.append('q', query.toString());
        body.append('series', seriesId.toString());
        return this.http.post(`${this.url}/datamanager/getRelationship`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getSeriesTypes() {
        return this.http.get(`${this.url}/datamanager/getSeriesTypes`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getFrequency() {
        return this.http.get(`${this.url}/datamanager/getFrequency`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getUnits() {
        return this.http.get(`${this.url}/datamanager/getUnits`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ActivateDeactiveSeries(n_series: number) {
        const body = new FormData();
        body.append('n_series', n_series.toString());
        return this.http.post(`${this.url}/datamanager/seriesactivatedeactivate`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddDataSeries(seriesId: number, date: string, value: number) {
        const body: { seriesId: number, date: string, value: number } = { seriesId: seriesId, date: date, value: value };
        return this.http.post(`${this.url}/datamanager/dataseries`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateDataSeries(seriesId: number, newdate: string, value: number, olddate: string) {
        const body: { seriesId: number, date: string, value: number, newdate: string } = { seriesId: seriesId, date: olddate, value: value, newdate: newdate };
        return this.http.put(`${this.url}/datamanager/dataseries`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteDataSeries(seriesId: number, date: string, value: number) {
        const _body: { seriesId: number, date: string, value: number } = { seriesId: seriesId, date: date, value: value };
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify(_body)
        };
        return this.http.delete(`${this.url}/datamanager/dataseries`, httpOptions).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadDataSeries(seriesId: number, csvFormData: { decimal: string, overwrite: string, uploadata: { date: string, value: number }[] }) {
        const body: { seriesId: number, decimal: string, overwrite: string, uploadata: { date: string, value: number }[] } = {
            seriesId: seriesId,
            decimal: csvFormData.decimal,
            overwrite: csvFormData.overwrite,
            uploadata: csvFormData.uploadata
        };
        return this.http.post(`${this.url}/datamanager/uploaddataseries`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditStock(id:number, ticker: string, seriesId: number, companyId: number) {
        const body = new FormData();
        if(id>0){
            body.append('id', id.toString());
        }
        body.append('ticker', ticker);
        body.append('seriesId', seriesId.toString());
        body.append('companyId', companyId.toString());
        return this.http.post(`${this.url}/datamanager/stock`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ExportSeries(seriesId: number){
        const body = new FormData();
        body.append('series', seriesId.toString());
        return this.http.post(`${this.url}/datamanager/ExportSeries`, body, { headers: this.headerSrv.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
}
