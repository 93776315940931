
import { catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HeaderService } from '../shared/header.service';
@Injectable()
export class Client360Service {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private headerSrv: HeaderService) { }
    SearchSMNotesTags(query: string){
        let Params = new HttpParams();
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/serviceman/search_tag`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));  
    }
    ShowSMNotesByTagId(tags: {id: string, weight: number, type: string, name: string}[],isClientInsight?:boolean){
        let Params = new HttpParams();
        Params = Params.append('tag', tags.map(t=>t.id).join());
        if(isClientInsight){
            Params = Params.append('ci', 'true');
        }
        return this.http.get(`${this.url}/serviceman/search_tag_notes`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));  
    }
    AddSMNote(note: string, tags:{id: string, weight: number, type: string, name: string}[],isClientInsight?:boolean){
        const body = new FormData();
        body.append('note', note);
        body.append('tag', tags.map(t=>t.id).join());
        if(isClientInsight){
            body.append('ci', 'true');
        }
        return this.http.post(`${this.url}/serviceman/tag_notes`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRecentNotes(){
        return this.http.get(`${this.url}/serviceman/recent_search_tag_notes`,{ headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
    GetClient360PivotTable(isExludeUser:boolean){
        let Params = new HttpParams();
        if(isExludeUser){
            Params = Params.append('no_user', isExludeUser.toString());
        }
        return this.http.get(`${this.url}/serviceman/pivot_table`,{ params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler))
    }
}