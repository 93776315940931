import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { ServiceManSearchModule } from '../../shared/smsearch/smsearch.module';
import { AuthenticationService } from '../../services';
import { ButtonModule } from 'primeng/button';
import { ServiceManNotesModule } from '../smnotes/smnotes.module';
@NgModule({
    imports: [ RouterModule, CommonModule, ServiceManSearchModule, ServiceManNotesModule,ButtonModule],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ],
    providers: [AuthenticationService]
})

export class NavbarModule {}
