import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ServiceManSearchComponent } from './smsearch.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ServiceManService } from '../../services/index';
@NgModule({
    imports: [ RouterModule, CommonModule, FormsModule, AutoCompleteModule],
    declarations: [ ServiceManSearchComponent ],
    exports: [ ServiceManSearchComponent ],
    providers: [ServiceManService]
})

export class ServiceManSearchModule {}
