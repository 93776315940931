
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { OnDestroy } from '@angular/core';
import { HeaderService } from './header.service';
@Injectable()
export class AuthenticationService implements OnDestroy {
    private url = environment.urlpath;
    private token: string;
    private version = '1.0.83';
    private subscriptionOne;
    public expirationDateTime: string;
    constructor(private http: HttpClient, private router: Router, private _cookieService: CookieService, private headerSrv: HeaderService) { }
    isValidToken(): string {
        const localStorageData = localStorage.getItem('AdminUserInfo');
        if (this.isAuthenticated()) {
            if (localStorageData) {
                const localParseData = JSON.parse(localStorageData);
                return localParseData.token;
            }
        } else {
            return null;
        }
    }
    getCookie(key: string) {
        return this._cookieService.get(key);
    }
    async checkUser(token) {
        const Headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return await this.http.get(`${this.url}/user`, { headers: Headers }).pipe(catchError(this.headerSrv._errorHandler)).toPromise();
    }

    getAdminUserInfo() {
        const localStorageData = localStorage.getItem('AdminUserInfo');
        if (localStorageData) {
            const localParseData = JSON.parse(localStorageData);
            return localParseData.Info;
        }
    }
    createCookie(loginCred) {
        const body = new FormData();
        body.append('email', loginCred.email);
        body.append('password', loginCred.password);
        body.append('code', loginCred.scode);
        body.append('logindoor', loginCred.logindoor);
        body.append('login', loginCred.login);
        body.append('needtoken', loginCred.needtoken);
    return this.http.post(`${this.url}/user/login`, body).pipe(catchError(this.headerSrv._errorHandler));
    }
    ValidateToken(tfmodel: {vfcode: string, authy: {token: string, message: string}, isResendEmail: boolean, resendTF: boolean}){
        const body = new FormData();
            body.append('token', tfmodel.authy.token);
            body.append('code', tfmodel.vfcode);
        return this.http.post(`${this.url}/user/validatetoken`, body).pipe(catchError(this.headerSrv._errorHandler));
    }
    ResendToken(tfmodel: {vfcode: string, authy: {token: string, message: string}, isResendEmail: boolean, resendTF: boolean}){
        const body = new FormData();
            body.append('token', tfmodel.authy.token);
            body.append('email', tfmodel.isResendEmail.toString());
        return this.http.post(`${this.url}/user/resendtoken`, body).pipe(catchError(this.headerSrv._errorHandler));
    }
    logout(): void {
        const cookieToken = this.getCookie('AdminLoginToken');
        if (cookieToken) {
            this._cookieService.removeAll();
        }
        localStorage.removeItem('AdminUserInfo');
        localStorage.removeItem('AdminUserInfoExpiry');
        this.expirationDateTime = null;
        localStorage.clear();
        sessionStorage.clear();
    }
    isAuthenticated(): boolean {
        const cookieToken = this.getCookie('AdminLoginToken');
        const localStorageExpiryDate = localStorage.getItem('AdminUserInfoExpiry');
        const localStorageData = localStorage.getItem('AdminUserInfo');
        // NO Cookie and NO Local Storage Data
        if (!cookieToken && !localStorageData) {
            localStorage.removeItem('AdminUserInfoExpiry');
            return false;
            // If Cookie Exist and NO Local Storage
        } else if (cookieToken && !localStorageData) {
            localStorage.removeItem('AdminUserInfoExpiry');
            const expiryDate = moment().add(1, 'days').toString();
            const options: CookieOptions = { expires: moment().add(1, 'days').toString() }
            this.subscriptionOne = this.checkUser(cookieToken).then((resp: any) => {
                localStorage.setItem('AdminUserInfo', JSON.stringify({ token: cookieToken, Info: resp.cert }));
                localStorage.setItem('AdminUserInfoExpiry', expiryDate);
                this._cookieService.put('AdminLoginToken', cookieToken, options);
            });
            return true;
        } else if (!cookieToken && localStorageData) {
            if (localStorageExpiryDate) {
                const date = new Date(localStorageExpiryDate);
                // If False local session is NOT expired, if True its is expired
                // console.log(moment(date).isBefore())
                if (moment(date).isBefore()) {
                    localStorage.removeItem('AdminUserInfo');
                    localStorage.removeItem('AdminUserInfoExpiry');
                    return false;
                } else {
                    return true;
                }
            } else {
                localStorage.removeItem('AdminUserInfo');
                localStorage.removeItem('AdminUserInfoExpiry');
                return false;
            }
        } else if (cookieToken && localStorageData) {
            return true;
        } else {
            return false;
        }
    }
    hasRole(compareRoles: string[]): boolean{
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const rolesExist = adminUserInfo.Info.roles.filter(userRoles => compareRoles.includes(userRoles));
            return rolesExist&&rolesExist.length?true:false;
        } else {
            return false
        }
    }
    // OUTSOURCED ACCESS CHECK
    isOutSourcedUser(): boolean {
        const role = 'Outsourced';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS Staff LEVEL ACCESS.
     * @return If Staff then return true
    */
    isStaffAccess(): boolean {
        const role = 'Staff';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS SERVICEMAN FULL LEVEL ACCESS.
     * @return If SERVICEMAN FULL then return true
    */
    isSmRegularAccess(): boolean {
        const role = 'Serviceman';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS SERVICEMAN FULL LEVEL ACCESS.
     * @return If SERVICEMAN FULL then return true
    */
    isSmFullAccess(): boolean {
        const role = 'Serviceman~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
     // SERVICEMAN SUPER ADMIN ACCESS CHECK
    isSMSuperAdminAccess(): boolean {
        const role = 'Serviceman~SuperAdmin';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    // SERVICEMAN FULL ACCESS AND LOGGED IN USER CHECK
    isSMandCompUserFullAccess(userId: number): boolean {
        const role = 'Serviceman~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            if (adminUserInfo.Info.qUser[0].user_id === userId) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    // INFOMAN FULL ACCESS AND LOGGED IN EDITOR CHECK
    isIMFullAccessAndActiveEditor(): {id: number, name: string} {
        const role = 'Infoman~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            if (hasRole) {
                return {id: adminUserInfo.Info.qUser[0].user_id, name: `${adminUserInfo.Info.qUser[0].first_name} ${adminUserInfo.Info.qUser[0].last_name}`};
            } else {
                return null;
            }
        }
    }
    // INFOMAN FULL ACCESS CHECK
    isIMFullAccess(): boolean {
        const role = 'Infoman~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    // INFOMAN FULL ACCESS CHECK
    isIMGateKeeperAccess(): boolean {
        const role = 'Infoman~GateKeeper';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    // INFOMAN SUPER ADMIN ACCESS CHECK
    isIMSuperAdminAccess(): boolean {
        const role = 'Infoman~SuperAdmin';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    // METAMANAGER FULL ACCESS CHECK
    isMMFullAccess(): boolean {
        const role = 'MetaManager~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    // SPECIFIC IT TEAM ACCESS CHECK
    DevTeam(): boolean {
        const CoolITDudes = [26612, 37780];
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = CoolITDudes.includes(adminUserInfo.Info.qUser[0].user_id);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS IT LEVEL ACCESS.
     * @return If IT then return true
     */
    isITAccess(): boolean {
        const role = 'IT';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS INTRANET IT LEVEL ACCESS.
     * @return If INTRANET IT then return true
     */
    isIntranetITAccess(): boolean {
        const role = 'IntranetIT';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS SALES LEVEL ACCESS.
     * @return If SALES then return true
     */
    isHasSalesAccess(): boolean {
        const role = 'SalesManager';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    /**
     * CHECKS IF USER HAS SALES FULL LEVEL ACCESS.
     * @return If SALES FULL then return true
     */
    isHasSalesFullAccess(): boolean {
        const role = 'SalesManager~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    isIStatFullAccess(): boolean {
        const role = 'IIStatman~FullAccess';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    isIntranetTeamsAccess(): boolean {
        const role = 'IntranetTeam';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    isIntranetBillingAccess(): boolean {
        const role = 'BillingGroup';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    isIntranetBillingGeneralAccess(): boolean {
        const role = 'BillingGroup~General';
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (adminUserInfo) {
            const hasRole = adminUserInfo.Info.roles.includes(role);
            return hasRole;
        }
    }
    GetExpirationTime(){
        if(this.expirationDateTime && this.expirationDateTime.length){
            return this.expirationDateTime;
        } else {
            return null;
        }
    }
    GetUserInfo(){
        const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
		if(adminUserInfo && adminUserInfo.hasOwnProperty('Info')){
            return adminUserInfo.Info.qUser[0];
        } else {
            return null;
        }
    }
    SaveExpirationTime(expirationDateTime: string){
        this.expirationDateTime = expirationDateTime
    }
    ngOnDestroy() {
        if (this.subscriptionOne) {
            this.subscriptionOne.unsubscribe();
        }
    }
}
