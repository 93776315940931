import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ServiceManNotesComponent } from './smnotes.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ServiceManService } from '../../services/index';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
@NgModule({
    imports: [ RouterModule, CommonModule, FormsModule, AutoCompleteModule, ButtonModule, DialogModule,
    TableModule],
    declarations: [ ServiceManNotesComponent ],
    exports: [ ServiceManNotesComponent ],
    providers: [ServiceManService]
})

export class ServiceManNotesModule {}
