<div class="center-wrapper">
    <div class="timer-warning" *ngIf="GetExpirationTime">
        Session expires {{GetExpirationTime}} 
    </div>
</div>
<nav #navbar class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button id="minimizeSidebar" class="btn btn-fill btn-icon"><i class="ti-more-alt"></i></button>
        </div>
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar bar1"></span>
                <span class="icon-bar bar2"></span>
                <span class="icon-bar bar3"></span>
            </button>
            <a class="navbar-brand">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse" *ngIf="getRoute()">
            <div class="mobile" *ngIf="isMobileMenu()">
                <form (keydown.enter)="$event.preventDefault()" class="navbar-form navbar-right navbar-search-form" role="search" style="display: inline-block;width: 35%;margin-right: 0px;">
                    <div class="input-group">
                            <smsearch-cmp *ngIf="getRoute()"></smsearch-cmp>
                    </div>
                </form>
                <!-- <ul class="nav navbar-nav navbar-right">
                    <li class="dropdown">
                        <a href="#notifications" class="dropdown-toggle btn-rotate" data-toggle="dropdown">
                            <i class="ti-bell"></i>
                            <span class="notification">5</span>
                            <p class="hidden-md hidden-lg">
                                Notifications
                                <b class="caret"></b>
                            </p>
                        </a>
                        <ul class="dropdown-menu">
                            <li><a href="#not1">Notification 1</a></li>
                            <li><a href="#not2">Notification 2</a></li>
                            <li><a href="#not3">Notification 3</a></li>
                            <li><a href="#not4">Notification 4</a></li>
                            <li><a href="#another">Another notification</a></li>
                        </ul>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</nav>