
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HeaderService } from '../shared/header.service';
@Injectable()
export class BIAggregatorService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private headerSrv: HeaderService) { }

    CompanyProfile(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', companyId.toString());
        return this.http.get(`${this.url}/metamanager/companyprofile`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    EditDeleteCompanyProfile(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/metamanager/companyprofile`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/metamanager/companyprofile`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetRelationshipTreeData(parentId: number, selReciLevel: number, reciWeight: number) {
        let Params = new HttpParams();
        Params = Params.append('level', selReciLevel.toString())
        Params = Params.append('weight', reciWeight.toString())
        Params = Params.append('parentId', parentId.toString());
        return this.http.get(`${this.url}/metamanager/getRelationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CompanyProfileNews(iMV: number, startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate)
        Params = Params.append('endDate', endDate)
        Params = Params.append('iMV', iMV.toString());
        return this.http.get(`${this.url}/metamanager/companyprofilenews`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchMetaValueQ(query: string) {
        // Excludes Company and Secondary Company
        // 1,2,3,4,6,90,133,1010
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', '-8');
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/metamanager/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetCompanyValueById(companyId: string) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId);
        return this.http.get(`${this.url}/metamanager/companyvaluebyid`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetTwitterStatusFeed() {
        return this.http.get(`${this.url}/metamanager/statusfeed`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    TwitterPreview(query: string, result_type: string, geocode: string, lang: string, locale: string, count: string, until: string, since_id: string,
        max_id: string){
        const body = new FormData();
        body.append('q', query);
        body.append('result_type', result_type);
        body.append('geocode', geocode);
        body.append('lang', lang);
        body.append('locale', locale);
        body.append('count', count);
        body.append('until', until);
        body.append('since_id', since_id);
        body.append('max_id', max_id);
        return this.http.post(`${this.url}/metamanager/TwitterPreview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TwitterQueryPreview(query: string, result_type: string, geocode: string, lang: string, locale: string, count: string, until: string, since_id: string,
        max_id: string, fingerprint: {id: number,name: string}[],fingerprintArr: { id: number, name: string, type: {id: number,name: string} }[], queryArr: string[]){
        const body = new FormData();
        body.append('q', query);
        body.append('result_type', result_type);
        body.append('geocode', geocode);
        body.append('lang', lang);
        body.append('locale', locale);
        body.append('count', count);
        body.append('until', until);
        body.append('since_id', since_id);
        body.append('max_id', max_id);
        body.append('fingerprint', fingerprint.map(fingerp=>fingerp.id).join());
        body.append('fingerprintArr', JSON.stringify(fingerprintArr.map(frArrayItem =>frArrayItem.id)));
        body.append('queryArr', JSON.stringify(queryArr));
        return this.http.post(`${this.url}/metamanager/TwitterQueryPreview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllNewsEdgeMapping() {
        return this.http.get(`${this.url}/metamanager/NewsEdgeMapping`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    UpdateNewsEdgeMapping(passedBody){
        let body = JSON.stringify(passedBody);
        return this.http.put(`${this.url}/metamanager/newsedgemapping_update`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchNewsEdgeOptions(){
        return this.http.get(`${this.url}/metamanager/newsedge_search_option`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchSuggestNewsEdge(query: string, option: string){
        const body = new FormData();
        body.append('q', query);
        body.append('option', option);
        return this.http.post(`${this.url}/metamanager/newsedge_search_suggest`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchNewsEdgePreview(qryText: string,qjson: {type: 'iMv'|'oMv',bound:string, optiontype:{id: string, name: string}, options: {"@code": number,"@id": string,"@name": string}[] }[], startDate: string, endDate: string, lang: {code: string, native: string, name: string}){
        const body = new FormData();
        body.append('startDate', startDate);
        body.append('endDate', endDate);
        body.append('lang', lang.code);
        if(qjson){
            body.append('qjson', JSON.stringify(qjson));
        }
        if(qryText){
            body.append('qryText', qryText);
        }
        return this.http.post(`${this.url}/metamanager/newsedge_search_preview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    LazySearchNewsEdgePreview(startDate: string, endDate: string, searchKey:string, first: number){
        const body = new FormData();
        body.append('startDate', startDate);
        body.append('endDate', endDate);
        body.append('searchKey', searchKey);
        body.append('first', first.toString());
        return this.http.post(`${this.url}/metamanager/newsedge_search_preview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchNewsEdgeHeadlineURLPreview(storyURL:string){
        const body = new FormData();
        body.append('storyURL', storyURL);
        return this.http.post(`${this.url}/metamanager/newsedge_news_read`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchNewsEdgeQueryPreview(qjson:any){
        const body = new FormData();
        body.append('qjson', JSON.stringify(qjson));
        return this.http.post(`${this.url}/metamanager/newsedge_query_preview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteTwitterFeed(id:number, companyId: number){
        const body = new FormData();
        body.append('id', id.toString());
        body.append('companyId', companyId.toString());
        return this.http.post(`${this.url}/metamanager/delete_twitter_feed`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgePreviewLang(){
        return this.http.get(`${this.url}/metamanager/language`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ActiveInactiveNewsEdgeScrapers(){
        return this.http.get(`${this.url}/metamanager/newsedge_scraper_active_inactive_report`, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PageMonitorReports(){
        return this.http.get(`${this.url}/metamanager/pagemonitor_scraper_active_inactive_report`, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBICompaniesData(){
        return this.http.get(`${this.url}/metamanager/GetBICompanies`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetDateRegexColData(){
        return this.http.get(`${this.url}/metamanager/GetDateRegexCol`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNonAutoPublishSelection(){
        return this.http.get(`${this.url}/metamanager/GetNonAutoPublishDest`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ChangeAutoPublish(id: number, key: string, value: boolean){
        const body = new FormData();
        body.append('id', id.toString());
        body.append('key_name', key);
        body.append('key_value', value.toString());
        return this.http.post(`${this.url}/metamanager/ChangeAutoPublish`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ChangeNonAutoPublishDest(id: number, destinationId: number){
        const body = new FormData();
        body.append('id', id.toString());
        body.append('destination', destinationId.toString());
        return this.http.post(`${this.url}/metamanager/ChangeNonAutoPublishDest`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBICompanyNews(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', companyId.toString());
        return this.http.get(`${this.url}/metamanager/GetBICompanyNews`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetBiaggNotes(mvId: number) {
        let Params = new HttpParams();
        Params = Params.append('MvId', mvId.toString());
        return this.http.get(`${this.url}/metamanager/biagg_notes`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteBiaggNotes(action: 'post'|'put'|'delete', notesBody:{notes: string, mvId: number, id?:number}){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(notesBody)
            };
            return this.http[action](`${this.url}/metamanager/biagg_notes`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/metamanager/biagg_notes`, JSON.stringify(notesBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    RefreshBIAggCache(){
        return this.http.get(`${this.url}/crons/build_biagg_cached`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetBiAggCoTotal(){
        return this.http.get(`${this.url}/metamanager/GetBIAggCo`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
}