
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ServiceManService } from '../../services/index';
import { AutoComplete } from 'primeng/autocomplete';
@Component({

    selector: 'smsearch-cmp',
    templateUrl: './smsearch.component.html',
    styleUrls: ['smsearch.component.css']
})
export class ServiceManSearchComponent implements OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();
    public searchSuggestData: any[] = [];
    public stext: any;
    public cioImg = 'assets/img/cio.png';
    constructor(private router: Router, private sManService: ServiceManService, ) { }
    ngOnInit() {
    }
    searchSuggest(query) {
        this.subscription.add(this.sManService.getSearchSuggest(query, 'normal').pipe(debounceTime(500),
            distinctUntilChanged()).subscribe((resp: any) => {
                for (let i = 0; i < resp.searchsuggest.length; i++) {
                    if (resp.searchsuggest[i].type === 'user') {
                        resp.searchsuggest[i].name = `👤 ${resp.searchsuggest[i].name}`;
                    } else if (resp.searchsuggest[i].type === 'company') {
                        resp.searchsuggest[i].name = `🏢 ${resp.searchsuggest[i].name}`;
                    } else if (resp.searchsuggest[i].type === 'marketreport') {
                        resp.searchsuggest[i].name = `📈 ${resp.searchsuggest[i].name}`;
                    } else if (resp.searchsuggest[i].type === 'i2report') {
                        resp.searchsuggest[i].name = `📄 ${resp.searchsuggest[i].name}`;
                    }
                }
                this.searchSuggestData = resp.searchsuggest;
                const tempItem = {
                    active: false,
                    code: 'a404',
                    type: 'addcompany',
                    name: `🤔 Cannot find the company? <em>Click Here</em> 🤔`
                }
                this.searchSuggestData = this.searchSuggestData.concat(tempItem);
            }));
    }
    selectedSearch(value) {
        if (value.type === 'user') {
            this.router.navigate(['/serviceman/users', value.code]);
        } else if (value.type === 'company') {
            this.router.navigate(['/serviceman/company', value.code]);
        } else if (value.type === 'marketreport' || value.type === 'i2report') {
            this.router.navigate(['/serviceman/reports', value.code]);
        } else if (value.type === 'addcompany') {
            this.router.navigate(['/serviceman/assigncompany']);
        } else {
            this.router.navigate(['/serviceman/search', value.code]);
        }
        this.stext = {}
    }
    selectedSearchEntered(event, stext, autoCompElem: AutoComplete) {
        event.preventDefault();
        if (stext) {
            if(typeof stext != 'string'){ console.log('NOT STRING!!', stext); return;}
            stext = stext.replace(/\s{2,}/g, ' ');
            if (stext.length >= 2) {
                this.router.navigate(['/serviceman/search'], { queryParams: { q: stext } });
                autoCompElem.hide();
                this.stext = {}
            };
        };
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}

