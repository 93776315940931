import { Component, OnInit, ViewChild, ElementRef, Renderer2} from "@angular/core";
import { ROUTES } from "../.././sidebar/sidebar.component";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { AuthenticationService } from "../../services";
import * as moment from "moment";
declare let swal: any;
const misc: any = { navbar_menu_visible: 0, active_collapse: true, disabled_collapse_init: 0};
declare var $: any;

@Component({
  selector: "navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private showTwoMinWarning: boolean = false;
  private showFiveMinWarning: boolean = false;
  @ViewChild("navbar-cmp") button;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    public router: Router,
    private authService: AuthenticationService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
    $("body").addClass("sidebar-mini");
    if ($("body").hasClass("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    $("#minimizeSidebar").click(function () {
      const $btn = $(this);
      if (misc.sidebar_mini_active === true) {
        $("body").removeClass("sidebar-mini");
        misc.sidebar_mini_active = false;
      } else {
        setTimeout(function () {
          $("body").addClass("sidebar-mini");
          misc.sidebar_mini_active = true;
        }, 300);
      }
      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event("resize"));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    });
  }
  isMobileMenu() {
    if ($(window).width() < 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }
  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  getRoute() {
    if ( this.location.path().includes("/serviceman")) {
      return true;
    } else {
      return false;
    }
  }
  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(2);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      const parent = this.listTitles[item];
      if (parent.path === titlee) {
        if(parent.hasOwnProperty('large_title')){
          return parent.large_title;
        }else{
          return parent.title;
        }
      } else if (parent.children) {
        const children_from_url = titlee.split("/")[2];
        for (let current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return "Dashboard";
  }
  get GetExpirationTime() {
    let expirationTime = this.authService.GetExpirationTime();
    if (expirationTime) {
      if (
        moment().diff(expirationTime, "minutes") === -5 &&
        this.showFiveMinWarning === false
      ) {
        swal({
          title: "Session Expiring in 5min!",
          text:
            "Save your work!, your session is about to expire in few minutes. You can logout and relogin at your earliest convenience to start a new session",
          type: "warning",
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok, I got it!',
        });
        this.showFiveMinWarning = true;
      }
      if (
        moment().diff(expirationTime, "minutes") === -2 &&
        this.showTwoMinWarning === false
      ) {
        swal({
          title: "Session Expiring in 2min!",
          text:
            "Save your work!, your session is about to expire in few minutes. You can logout and relogin at your earliest convenience to start a new session",
          type: "warning",
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok, I got it!',
        });
        this.showTwoMinWarning = true;
      }
      return moment(expirationTime).fromNow();
    }
  }
  getPath() {
    // console.log(this.location);
    return this.location.prepareExternalUrl(this.location.path());
  }
}
