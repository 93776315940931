import { Injectable } from '@angular/core';
import { HeaderService } from '../shared/header.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
declare const Pusher: any;
import { AuthenticationService } from '../shared/auth.service';
@Injectable()
export class PusherService {
    public url = environment.urlpath;
    private pusherClient;
    private activepPusherClientStatus;
    public channel;
    constructor(private authenticationService: AuthenticationService) {
        this.pusherClient = this.AuthorizePusher();
        if(this.pusherClient){
            this.pusherClient.connection.bind('state_change', (states) => {
                this.activepPusherClientStatus = states;
			});
        }
        console.log(`😀 Starting Pusher in serivce!`,this.pusherClient)
     }
    AuthorizePusher() {
        const adminUser = this.authenticationService.isValidToken();
        // if (!environment.production) {
        //     Pusher.logToConsole = true;
        // }
        return new Pusher(environment.pusher.key, {
            authEndpoint: `${this.url}/infoman/pusher_auth`, auth: {
                headers: {
                    'Authorization': `Bearer ${adminUser}`
                }
            },
            cluster: environment.pusher.cluster, forceTLS: true
        });
    }
    StartPusherPresenceChannelByArticleId(articleId: string, eventId: string) {
        const channel = this.pusherClient.subscribe(`presence-${articleId}-channel`);
        return channel;
    }
    get GetActivePusherClient() {
        return this.pusherClient;
    }
    get GetActivepPusherClientStatus() {
        return this.activepPusherClientStatus;
    }
    unsubscribeFromChannel(channelData): void {
        this.pusherClient.unsubscribe(channelData.name);
    }
}
