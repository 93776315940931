
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HeaderService } from '../shared/header.service';
interface PageData { intro: { cta_button_text: string; paragraph: string[]; cta_text: string; cta_button_url: string; }; footer_cta: { button_text: string; subtext: string; title: string; button_url: string; }; market_driver: { img: string; subtext: string; cta_button_text: string; title: string; bullet_points: { text: string; lead: string; }[]; cta_button_url: string; }; title: string; subtitle: string; solutions: { img: string; subtext: string; title: string; bullet_points: { text: string; lead: string; }[]; }; }
interface IndustryPage { page_data: PageData; id: string; }
@Injectable()
export class OverViewService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private headerSrv: HeaderService) { }
    getQuoteOfDay() {
        return this.http.get(`${this.url}/intranet/getQuoteOfTheDay`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getOfficeHolidays(reqDate:{start: any, end: any}) {
        let Params = new HttpParams();
        Params = Params.append('start', reqDate.start)
        Params = Params.append('end', reqDate.end)
        return this.http.get(`${this.url}/serviceman/getofficeholidays`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getOfficeAnnounceMent() {
        return this.http.get(`${this.url}/intranet/getNews`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    addOfficeAnnounceMent(announceContent: string) {
        const body = new FormData();
        body.append('content', announceContent);
        return this.http.post(`${this.url}/intranet/addnews`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    editOfficeAnnounceMent(announceId: number, announceContent: string) {
        const body = new FormData();
        body.append('id', announceId.toString())
        body.append('content', announceContent);
        return this.http.post(`${this.url}/intranet/editnews`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    deleteOfficeAnnounceMent(announceId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', announceId.toString())
        return this.http.get(`${this.url}/intranet/deletenews`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getRandomGiphyByTagName(tags: string) {
        return this.http.get(`https://api.giphy.com/v1/gifs/random?api_key=Go5LhoDsTlgb8rWCUiRcDIMKiMZLQARp&tag=${tags}&rating=G`).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getRandomGiphyByTagId(tagId) {
        return this.http.get(`https://api.giphy.com/v1/gifs/${tagId}?api_key=Go5LhoDsTlgb8rWCUiRcDIMKiMZLQARp`).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getTimeSheet(userId, timeId, version: boolean) {
        let Params = new HttpParams();
        if (userId) {
            Params = Params.append('userId', userId)
        };
        if (timeId) {
            Params = Params.append('timeId', timeId)
        };
        if (version) {
            Params = Params.append('v', '2')
        };
        return this.http.get(`${this.url}/intranet/timesheet`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    removeTime(id, userId?: string, isV2?:boolean) {
        let Params = new HttpParams();
        if (isV2) {
            Params = Params.append('v', '2');
        };
        const body = new FormData();
        body.append('id', id);
        if(userId){
            body.append('userId', userId);
        }
        return this.http.post(`${this.url}/intranet/removeTime`, body, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    addTime(timeBody, isV2?:boolean) {
        let Params = new HttpParams();
        if (isV2) {
            Params = Params.append('v', '2');
        };
        return this.http.post(`${this.url}/intranet/addTime`, JSON.stringify(timeBody), { params: Params,headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    editTime(timeBody, isV2?:boolean) {
        let Params = new HttpParams();
        if (isV2) {
            Params = Params.append('v', '2');
        };
        return this.http.put(`${this.url}/intranet/addTime`, JSON.stringify(timeBody), { params: Params,headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getAllTimesheetUser() {
        return this.http.get(`${this.url}/intranet/getalltimesheetusers`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    getAllTimesheetManager() {
        return this.http.get(`${this.url}/intranet/getalltimesheetmanagers`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getAllTimesheetPeriod() {
        return this.http.get(`${this.url}/intranet/gettimesheetperiods`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    getAllTimesheetPeriodByYear() {
        return this.http.get(`${this.url}/intranet/gettimesheetperiods/?groupYear=true`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    xlsTimeSheet(xlsBody,noblob) {
        const body = new FormData();
        if (xlsBody.userId!==undefined) {
            body.append('userId', xlsBody.userId);
        }
        if (xlsBody.allusers!==undefined) {
            body.append('allusers', xlsBody.allusers);
        }
        if (xlsBody.period!==undefined) {
            body.append('period', xlsBody.period);
        }
        if (xlsBody.range!==undefined) {
            body.append('range', xlsBody.range);
        }
        if (xlsBody.slyear!==undefined) {
            body.append('slyear', xlsBody.slyear);
        }
        let headerParam = {
            headers: this.headerSrv.returnHeader()
        }
        if (noblob===null) {
            headerParam['responseType']='blob';
            headerParam['observe']='response';
        };
        return this.http.post(`${this.url}/intranet/xlstimesheet`, body, headerParam).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    approveTimesheet(userId,timeId) {
        const body = new FormData();
        body.append('userId', userId.toString());
        body.append('timeId', timeId.toString());
        return this.http.post(`${this.url}/intranet/approveTimesheet`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getFWEmployee() {
        return this.http.get(`${this.url}/intranet/getFWEmployee`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    saveFWEmployee(id: number, dob: string, cellph: string, altemail: string, dept: number, empstat: number, role: string, hd:string) {
        const body = new FormData();
        body.append('userId', id.toString());
        body.append('dob', dob);
        body.append('cellph', cellph);
        body.append('altemail', altemail);
        body.append('dept', dept.toString());
        body.append('empstat', empstat.toString());
        body.append('role', role);
        body.append('hd', hd);
        return this.http.post(`${this.url}/intranet/saveFWEmployee`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCategory() {
        return this.http.get(`${this.url}/intranet/getCatSubcat`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    saveCategory(id: number, category: string, subcat: string, deleteCat: boolean) {
        const body = new FormData();
        if (id!==null) {
            body.append('id', id.toString());
        };
        if (category!==null) {
            body.append('category', category.toString());
        };
        if (subcat!==null) {
            body.append('subcat', subcat.toString());
        };
        if (deleteCat!==null) {
            body.append('deleteCat', deleteCat.toString());
        };
        return this.http.post(`${this.url}/intranet/saveCatSubcat`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getPOExpenseMeta(type: any) {
        let Params = new HttpParams();
        if (type!==null) {
            Params = Params.append('type', type);
        };
        return this.http.get(`${this.url}/intranet/poexpensemeta`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaValueExt(type: any) {
        let Params = new HttpParams();
        if (type!==null) {
            Params = Params.append('type', type);
        };
        return this.http.get(`${this.url}/intranet/getmetavalueext`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateExpPO(exppoBody) {
        return this.http.put(`${this.url}/intranet/saveExpensePO`, JSON.stringify(exppoBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteExpPO(exppoBody) {
        console.log(exppoBody)
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify(exppoBody)
        };
        return this.http.delete(`${this.url}/intranet/saveExpensePO`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddExpPO(exppoBody) {
        return this.http.post(`${this.url}/intranet/saveExpensePO`, JSON.stringify(exppoBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteExpensePO(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/saveExpensePO`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/saveExpensePO`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    getAllExpenses(userId?: number,expensePOId?: number) {
        let Params = new HttpParams();
        if(userId){
            Params = Params.append('userId', userId.toString());
        }else if(expensePOId){
            Params = Params.append('id', expensePOId.toString());
        }
        return this.http.get(`${this.url}/intranet/getAllExpenses`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCommCalDates(startDate, endDate: any, id: number){
        let Params = new HttpParams();
        if(id){
            Params = Params.append('id', id.toString());
        } else{
            Params = Params.append('start', startDate);
            Params = Params.append('end', endDate);
        }
        return this.http.get(`${this.url}/serviceman/commcalendar`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    addCommCal(startDate: any, endDate: any, title: string, description: string, eventType: number){
        let body = {
            'FromDate':startDate,
            'ToDate':endDate,
            'Title':title,
            'Description':description,
            'CalType': eventType.toString()
        }
        return this.http.post(`${this.url}/serviceman/commcalendarsave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    updateCommCal(id: number, startDate: any, endDate: any, title: string, description: string, eventType: number){
        let body = {
            'Id': id.toString(),
            'FromDate':startDate,
            'ToDate':endDate,
            'Title':title,
            'Description':description,
            'CalType': eventType.toString()
        }
        return this.http.put(`${this.url}/serviceman/commcalendarsave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    deleteCommCal(id: number){
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({Id:id.toString()})
        };
        return this.http.delete(`${this.url}/serviceman/commcalendarsave`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetTestUsers(){
        return this.http.get(`${this.url}/serviceman/i2users`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetTestReport(query: string){
        let Params = new HttpParams();
        Params = Params.append('q', query);
        Params = Params.append('type', 'mapreportdaily');
        return this.http.get(`${this.url}/serviceman/searchsuggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllCommDrafts(){
        return this.http.get(`${this.url}/serviceman/commdraft`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditCommDrafts(id: number, status: 0 | 1 | 2 | 3, name: string, pre: string, post: string, startDate: string,
     endDate: string, testReports: {parentId: number, imvselect: number, relationshipid: number, template: number, id: string }[],
    testUsers: {id: number, name: string}[], testDateTime: string, global: boolean){
        console.log('TEst DateTime', testDateTime)
        const statusCode = { 0:"Testing Mode",1:"Approval sent",2:"Approve",3: "Disapprove"};
        let body = {
            id: null,
            status: status.toString(),
            name: name,
            pre: pre,
            post: post,
            startDate: startDate,
            endDate: endDate,
            testReports: testReports,
            testUsers:  testUsers,
            testDateTime: testDateTime,
            global: global
        }
        
        if(id){
            body.id = id.toString();
            return this.http.put(`${this.url}/serviceman/commsave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        } else {
            delete body.id;
            return this.http.post(`${this.url}/serviceman/commsave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
        }
    }
    DeleteCommDrafts(id: number){
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({id:id.toString()})
        };
        return this.http.delete(`${this.url}/serviceman/commsave`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAmbleTemplates() {
        return this.http.get(`${this.url}/serviceman/prepostambletemplates`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GlobalAmblePreview(reportData: any, pre: string, post: string){
        let body: any = {
            'frequency':reportData.frequency,
            'id': reportData.id,
            'name': reportData.name,
            'amble':{
                pre: pre,
                post: post
            }
        }
        if(reportData.id.includes('c')){
            body.parentId = reportData.parentId,
            body.imvselect = reportData.imvselect,
            body.relationshipId = reportData.relationshipId,
            body.template = reportData.template
        }
        return this.http.post(`${this.url}/serviceman/reportpreview`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetSearchReportsAndCompanies() {
        return this.http.get(`${this.url}/serviceman/SearchReport`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllPTO(userId?: number){
        let Params = new HttpParams();
        if(userId && typeof userId === 'number'){
            Params = Params.append('userId', userId.toString());
        }
        return this.http.get(`${this.url}/intranet/pto`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPTOTransByUser(userId: number){
        let Params = new HttpParams();
            Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/intranet/ptotransactions`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeletePTOTrans(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/ptotransactions`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/ptotransactions`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    ApprovePTOTrans(submitData: any){
        const body = new FormData();
        body.append('id', submitData.id.toString());
        body.append('reqId', submitData.reqId.id.toString());
        body.append('description', submitData.description);
        body.append('startDate', submitData.startDate);
        body.append('endDate', submitData.endDate);
        return this.http.post(`${this.url}/intranet/approveptotransactions`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetSickLeaveTransByUser(userId: number){
        let Params = new HttpParams();
            Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/intranet/sickleavetransactions`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPTOAccuralTransactions(userId: number){
        let Params = new HttpParams();
            Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/intranet/ptoaccrues`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAnnualDepositTransactions(userId: number, type: number){
        let Params = new HttpParams();
            Params = Params.append('userId', userId.toString());
            // Type 1 => PTO, Type 2 => Sick Leave, Type 3 => Award
            Params = Params.append('type', type.toString());
        return this.http.get(`${this.url}/intranet/pto_sickleave_annual`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllSickLeave(userId?: number){
        let Params = new HttpParams();
        if(userId && typeof userId === 'number'){
            Params = Params.append('userId', userId.toString());
        }
        return this.http.get(`${this.url}/intranet/sickleave`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteSickLeaveTrans(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/sickleavetransactions`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/sickleavetransactions`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetPTOAwardTransByUser(userId: number){
        let Params = new HttpParams();
            Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/intranet/ptoaward`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteAwardPTO(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/ptoaward`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/ptoaward`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetPartTimeSickLeaveTransByUser(userId: number){
        let Params = new HttpParams();
            Params = Params.append('userId', userId.toString());
        return this.http.get(`${this.url}/intranet/parttimesickleave`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeletePartTimeSickLeave(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/parttimesickleave`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/parttimesickleave`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetAllI2Users(seperateEmail?:boolean) {
        let Params = new HttpParams();
        if(seperateEmail && typeof seperateEmail === 'boolean'){
            Params = Params.append('seperateEmail', seperateEmail.toString());
        }
        return this.http.get(`${this.url}/intranet/i2users`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddSickLeave(userId: {id: number, name: string}, currentBalance: number){
        const body = new FormData();
        body.append('userId', userId.id.toString());
        body.append('currentBalance', currentBalance.toString());
        return this.http.post(`${this.url}/intranet/sickleave`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateSickLeave(userId: {id: number, name: string}, currentBalance: number){
        let body: any = {
            'userId':userId.id,
            'currentBalance': currentBalance
        };
        return this.http.put(`${this.url}/intranet/sickleave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddUsersPTO(userId: {id: number, name: string},dateOfHire:string,currentBalance:number, pendBalance: number, carryoverBalance: number){
        const body = new FormData();
        body.append('userId', userId.id.toString());
        body.append('dateOfHire', dateOfHire.toString());
        body.append('carryoverBalance', carryoverBalance.toString());
        body.append('currentBalance', currentBalance.toString());
        body.append('pendBalance', pendBalance.toString());
        return this.http.post(`${this.url}/intranet/pto`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateUsersPTO(userId: {id: number, name: string},dateOfHire:string,currentBalance:number, pendBalance: number, carryoverBalance:number){
        let body: any = {
            'userId':userId.id,
            'dateOfHire': dateOfHire,
            'carryoverBalance': carryoverBalance,
            'currentBalance': currentBalance,
            'pendBalance': pendBalance
        };
        return this.http.put(`${this.url}/intranet/pto`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPTOSickLeaveTransactions(userId: number,startDate: string, endDate: string, dept: number){
        let Params = new HttpParams();
        if(userId){
            Params = Params.append('userId', userId.toString());
        }
        if(dept){
            Params = Params.append('dept', dept.toString());
        }
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/intranet/ptosicktransactions`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetPTOSelections() {
        return this.http.get(`${this.url}/intranet/ptoselections`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetEmployeeDepartment() {
        return this.http.get(`${this.url}/intranet/getDept`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetEmploymentStatus() {
        return this.http.get(`${this.url}/intranet/getEmployementStatus`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetEmployeeRole() {
        return this.http.get(`${this.url}/intranet/getRole`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteAdminComapnyHolidays(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/holiday`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/holiday`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetAdminComapnyHolidays(){
        return this.http.get(`${this.url}/intranet/holiday`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadReceiptsEditor(fileData: File[]) {
        const body: FormData = new FormData();
        fileData.forEach(file => {
            body.append('upload', file, file.name);
        });
        return this.http.post(`${this.url}/intranet/receiptupload`, body, { reportProgress: true, observe: 'events', headers: this.headerSrv.returnHeader() }).pipe(
            map((event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                  case HttpEventType.Response:
                    return event.body;
                  default:
                    return { status: 'progress', message: event.type };
                }
              }),
            catchError(this.headerSrv._errorHandler)
        );
    }
    PrintExpensePO(parentExpensePOId: number){
        let Params = new HttpParams();
        Params = Params.append('id', parentExpensePOId.toString());
        return this.http.get(`${this.url}/intranet/print_expense_po`, { params: Params, headers: this.headerSrv.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteAskAda(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/intranet/ask_alfred`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/ask_alfred`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetAskAda(userId: number){
        let Params = new HttpParams();
        Params = Params.append('userId', userId.toString());
        Params = Params.append('rejected', '-1');
        return this.http.get(`${this.url}/intranet/ask_alfred`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBICompaniesData(){
        return this.http.get(`${this.url}/intranet/GetBICompanies`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetCompanySelection(){
        return this.http.get(`${this.url}/intranet/GetCompanyList`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetLanguageSelection(){
        return this.http.get(`${this.url}/intranet/language`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetIndustrySeleciton(){
        return this.http.get(`${this.url}/intranet/GetAboutList`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetContextSeleciton(){
        return this.http.get(`${this.url}/intranet/GetContextList`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetGeographySelection(){
        return this.http.get(`${this.url}/intranet/GetGeographyList`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetKeywordSelection(){
        return this.http.get(`${this.url}/intranet/GetKeywordList`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetReqStatusSelection(){
        return this.http.get(`${this.url}/intranet/GetReqStatusList`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPMCheckFrequency(){
        return this.http.get(`${this.url}/intranet/pm_freq_check/?v=2`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostAdaRequestSyncAgg(askAdaReqId: number){
        let Params = new HttpParams();
        Params = Params.append('id', askAdaReqId.toString());
        return this.http.get(`${this.url}/intranet/update_biagg`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    UpdateAskAdaFeedRequest(formData: {companyId: string, newsedge: string, pagemonitor: string, twitter: string}){
        const body = new FormData();
        body.append('companyId', formData.companyId.toString());
        if(formData.newsedge){
            body.append('newsedge', formData.newsedge);
        }
        if(formData.pagemonitor){
            body.append('pagemonitor', formData.pagemonitor);
        }
        if(formData.twitter){
            body.append('twitter', formData.twitter);
        }
        return this.http.post(`${this.url}/intranet/AddPropRequest`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBICompanyNews(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', companyId.toString());
        return this.http.get(`${this.url}/infoman/GetBICompanyNews`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostLinkedInURLToAdaRequest(id: number, linkedin: string) { 
        const body = new FormData();
        body.append('id', id.toString());
        body.append('linkedin', linkedin);
        return this.http.post(`${this.url}/intranet/update_linkedin`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    InactiveLinkedInURLToAdaRequest(id: number, inactive: boolean) { 
        const body = new FormData();
        body.append('id', id.toString());
        body.append('inactive', inactive.toString());
        return this.http.post(`${this.url}/intranet/update_linkedin`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ActionExpensePO(id: number, approval_action: number, approval_action_to:string, status: number) {
        const body = new FormData();
        body.append('id', id.toString());
        if(typeof status === 'number'){
            body.append('status', status.toString());
        }
        if(typeof approval_action === 'number'){
        // approval_action (numeric) => required for send approval => send approval action number
        body.append('approval_action', approval_action.toString());
        }
        if(approval_action_to){
        // approval_action_to (numeric) => required for send approval => send approval to selected person
        body.append('approval_action_to', approval_action_to.toString());
        }
        return this.http.post(`${this.url}/intranet/actionExpensePO`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetExpenseReport(startDate: string, endDate: string, frequency: number, userId: {id: number, name: string, email: string}[], ExpStatus: number){
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        Params = Params.append('frequency', frequency.toString());
        if(userId && userId.length){
            Params = Params.append('userId', userId.map(uItem => uItem.id).join());
        }
        Params = Params.append('ExpStatus', ExpStatus.toString());
        return this.http.get(`${this.url}/intranet/getExpenseReport`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetExpensePOAdminSendApproval(){
        let Params = new HttpParams();
        Params = Params.append('array', true.toString());
        return this.http.get(`${this.url}/intranet/getExpenseApprovalAction`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetExpensePOAdminSendApprovalTo(){
        let Params = new HttpParams();
        Params = Params.append('array', true.toString());
        return this.http.get(`${this.url}/intranet/getExpenseApprovalActionTo`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetExpensePOAdminFrequency(){
        let Params = new HttpParams();
        Params = Params.append('array', true.toString());
        return this.http.get(`${this.url}/intranet/getExpenseReportFreq`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetExpensePOAdminStatus(){
        let Params = new HttpParams();
        Params = Params.append('array', true.toString());
        return this.http.get(`${this.url}/intranet/getExpensePOStatus`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    getSpotifyPlaylist(){
        return this.http.get(`${this.url}/intranet/myspotify`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SaveSpotifyPlaylist(playListId: string) {
        const body = new FormData();
        body.append('id', playListId);
        return this.http.post(`${this.url}/intranet/myspotify`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RenewAutoPublishPM(selectedExpiredPMRows:{AI: {status: false;name: string;}[];block: string;expired: string;expired_stat: boolean;id: number;name: string;}[]) {
        const body = new FormData();
        body.append('id', selectedExpiredPMRows.map(pmRenew=>pmRenew.id).join());
        return this.http.post(`${this.url}/infoman/RenewAutoPublishPM`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetBiaggNotes(mvId: number) {
        let Params = new HttpParams();
        Params = Params.append('MvId', mvId.toString());
        return this.http.get(`${this.url}/intranet/biagg_notes`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetPMExpirations() {
        return this.http.get(`${this.url}/infoman/pm_expirations`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetPageMonitorLogs(BiCompanyId: number){
        let Params = new HttpParams();
        Params = Params.append('id', BiCompanyId.toString());
        return this.http.get(`${this.url}/intranet/GetPMLogs`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SubmitChat(question: string, type: 0 | 1) {
        const body = new FormData();
        body.append('question', question);
        body.append('type', type.toString());
        return this.http.post(`${this.url}/intranet/chat`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetInitChatHistory() {
        return this.http.get(`${this.url}/intranet/chat_history`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLazyChatHistory(page: number,rowcount: number) {
        let Params = new HttpParams();
        Params = Params.append('page', page.toString());
        Params = Params.append('rowcount', rowcount.toString());
        return this.http.get(`${this.url}/intranet/chat_history`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetChatHistoryById(chatId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', chatId.toString());
        return this.http.get(`${this.url}/intranet/chat_history`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetWebsocketId() {
        return this.http.get(`${this.url}/intranet/chat2`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AIChatLogsLikes(chatId:number,response: 'Claude' | 'ChatGPT' | 'Articles' | "Llama", like_dislike: boolean) {
        const body = new FormData();
        body.append('chatId', chatId.toString());
        body.append('response', response);
        body.append('like_dislike', like_dislike.toString());
        return this.http.post(`${this.url}/intranet/chat_like`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AIChatLogsLikes1(user:number,date: string,question:string,response: 'Claude' | 'ChatGPT' | 'Articles' | "Llama", like_dislike: boolean) {
        const body = new FormData();
        body.append('user', user.toString());
        body.append('date', date);
        body.append('question', question);
        body.append('response', response);
        body.append('like_dislike', like_dislike.toString());
        return this.http.post(`${this.url}/intranet/chat_like1`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    
    AskQuestionByModel(model:'gpt' | 'claude' | "llama",id:number){
        const body = new FormData();
        body.append('model', model);
        body.append('id', id.toString());
        return this.http.post(`${this.url}/intranet/ask_question`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    RegisterSSOToken(idToken: string){
        const body = new FormData();
        body.append('assertion', idToken);
        return this.http.post(`${this.url}/teamshare/sso`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ShareToTeams(shareTo: string, message: string, storyId: number, ai_summary: 1 | 0, article_title: string, article_content: string,
        article_url: string, upload: File){
        const body = new FormData();
        body.append('to', shareTo);
        body.append('message', message);
        if(storyId){
            body.append('storyId', storyId.toString());
            body.append('ai_summary', ai_summary.toString());
        }
        if(article_url){
            if(article_title){
                body.append('article_title', article_title);
            }
            if(article_content){
                body.append('article_content', article_content);
            }
            body.append('article_url', article_url);
        }
        if(upload){
            body.append('upload', upload);
        }
        return this.http.post(`${this.url}/teamshare/bot`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ListAllTeamsUsersAndChannels(){
        return this.http.get(`${this.url}/teamshare/my_to`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    I2ChatBotMicrositesQuestions(grouping: string){
        let Params = new HttpParams();
        Params = Params.append('grouping', grouping);
        return this.http.get(`${this.url}/intranet/i2chatbot_microsite`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    I2ChatBotMicrositesAnalytic(startDate: string, endDate: string, grouping: string){
        const body = new FormData();
        body.append('startDate', startDate);
        body.append('endDate', endDate);
        body.append('grouping', grouping);
        return this.http.post(`${this.url}/intranet/i2chatbot_microsite_analytic`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    I2ChatBotMicrositesAnalyticList(){
        return this.http.get(`${this.url}/intranet/i2chatbot_microsite_list`, {  headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetI2ChatFast(enteredText: string){
        let Params = new HttpParams();
        Params = Params.append('question', enteredText);
        return this.http.get(`${this.url}/intranet/chat_fast`, {  params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRBotLogs(engine: 'pinecone' | null){
        let Params = new HttpParams();
        if(engine === 'pinecone'){
            Params = Params.append('engine', engine);
        }
        return this.http.get(`${this.url}/intranet/rbot_logs`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRBotLogsById(id: number, engine: 'pinecone' | null){
        let Params = new HttpParams();
        Params = Params.append('id', id.toString());
        if(engine === 'pinecone'){
            Params = Params.append('engine', engine);
        }
        return this.http.get(`${this.url}/intranet/rbot_logs`, {  params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostRBotNewQuestion(prompt: string, mvIdsArray: {id: number, name: string, type:{id: number, name: string} }[]){
        const body = new FormData();
        if(prompt){
            body.append('prompt', prompt);
        }
        if(mvIdsArray && mvIdsArray.length){
            body.append('mvIds', mvIdsArray.map(mvid=>mvid.id).join());
        }
        return this.http.post(`${this.url}/intranet/rbot_search`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostRBotNewQuestionV2(question: string){
        const body = new FormData();
        body.append('question', question);
        return this.http.post(`${this.url}/intranet/pinecone_search`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    DeleteAliasFromKeyword(chat_id:number,mv_id: number, alias: string){
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({chat_id:chat_id,mv_id:mv_id, alias: alias})
        };
        return this.http.delete(`${this.url}/intranet/keyword_alias`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddAliasToKeyword(keyword: {id: number, name: string}[], alias: string,){
        let Params = new HttpParams();
        Params = Params.append('alias', alias);
        const body = keyword.map((key)=>{return{mv_id: key.id, keyword:key.name}});
        return this.http.post(`${this.url}/intranet/keyword_alias`, JSON.stringify(body), { params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddAliasToCompany(metaValueId: number, aliasStringArray: string[]){
        let body = {id: metaValueId,aliases:aliasStringArray};
        return this.http.put(`${this.url}/intranet/meta_alias`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchMetaValueQ(metaTypeId: number | number[], metaValueId: number, query: string, skipX?: boolean) {
        const body = new FormData();
        if(typeof metaTypeId === 'number'){
            body.append('metaTypeId', metaTypeId.toString());
        }else{
            body.append('metaTypeId', metaTypeId.join());
        }
        if (metaValueId) {
            body.append('id', metaValueId.toString());
        }
        body.append('q', query);
        if (skipX && skipX === true) {
            body.append('skipX', 'true');
        }
        return this.http.post(`${this.url}/intranet/metavalueq`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetInitLinkedInMeta(Rowcount:number, Pageno: number) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        return this.http.get(`${this.url}/intranet/linkedin_meta`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLazyLinkedInMeta(Rowcount:number, Pageno: number, sort_order: number, sort: string, Type: string, Name: string, Title: string, Industry: {id: number, name: string, type:{id: number, name: string} }[]) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        if(sort_order != null){
            Params = Params.append('sort_order', sort_order.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(Type){
            Params = Params.append('Type', Type);
        }
        if(Name){
            Params = Params.append('Name', Name);  
        }
        if(Title){
            Params = Params.append('Title', Title);  
        }
        if(Industry && Industry.length){
            Params = Params.append('industry', Industry.map(mvindustry=>mvindustry.id).join());  
        }
        return this.http.get(`${this.url}/intranet/linkedin_meta`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetInitSalesList(Rowcount:number, Pageno: number) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        return this.http.get(`${this.url}/intranet/my_contact_list`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLazySalesList(Rowcount:number, Pageno: number, sort_order: number, sort: string, first_name: string, last_name: string, company: string, email: string, title: string, phone: string ,industry: string, linkedin: string, list_name: string,
        notes: string, owner_name: string) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        if(sort_order != null){
            Params = Params.append('sort_order', sort_order.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(first_name){
            Params = Params.append('first_name', first_name);  
        }
        if(last_name){
            Params = Params.append('last_name', last_name);  
        }
        if(company){
            Params = Params.append('company', company);  
        }
        if(email){
            Params = Params.append('email', email);  
        }
        if(title){
            Params = Params.append('title', title);  
        }
        if(phone){
            Params = Params.append('phone', phone);  
        }
        if(industry){
            Params = Params.append('industry', industry);  
        }
        if(linkedin){
            Params = Params.append('linkedin', linkedin);  
        }
        if(list_name){
            Params = Params.append('list_name', list_name);  
        }
        if(notes){
            Params = Params.append('notes', notes);  
        }
        if(owner_name){
            Params = Params.append('owner_name', owner_name);  
        }
        return this.http.get(`${this.url}/intranet/my_contact_list`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    UploadSalesList(list_name: string, file_data: File){
        const body: FormData = new FormData();
        body.append('list_name', list_name);
        body.append('file_data', file_data, file_data.name);
        return this.http.post(`${this.url}/intranet/upload_list`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditSalesUserList(action: 'post'|'put'|'delete', notesBody:{first_name: string, last_name: string, company: string, email: string, title: string, phone: string, industry: string, linkedin: string, list_name: string,
        notes: string, owner_name: string}){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(notesBody)
            };
            return this.http[action](`${this.url}/intranet/my_contact_list`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/my_contact_list`, JSON.stringify(notesBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    GetEventType(){
        return this.http.get(`${this.url}/intranet/geteventtype`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchEvents(searchEventsBody) {
        // {“company”: array of object,“EventType”: array of object,“About”: array of object,“Affect”: array of object,
        //  “Keyword”: array of object,“Country”: array of object,“State”: array of object,“FromDate”: string (ISO Date),
        // “ToDate”: string (ISO Date),“EventName”: string}
        return this.http.post(`${this.url}/intranet/searchevent`, JSON.stringify(searchEventsBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMetaValueQByMetaTypeId(metaTypeId: number, metaValueId: number, skipX?: boolean) {
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', metaTypeId.toString());
        if (metaValueId) {
            Params = Params.append('id', metaValueId.toString());
        }
        if (skipX && skipX === true) {
            Params = Params.append('skipX', 'true');
        }
        return this.http.get(`${this.url}/intranet/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() })
            .pipe(map((resp: any) => { if (resp.metavalueQ.data && metaValueId) { resp.metavalueQ.data.metaValueId = metaValueId }; return resp; }), catchError(this.headerSrv._errorHandler));
    }
    getEventCalDates(startDate: any, endDate: any){
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/intranet/industry_event`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetIndustryEventsById(id: number,repeat_id: string){
        let Params = new HttpParams();
        Params = Params.append('id',id.toString());
        if(repeat_id){
            Params = Params.append('repeat_id',repeat_id);
        }
        return this.http.get(`${this.url}/intranet/industry_event`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteIndustryEvents(action: 'post'|'put'|'delete', eventData:{event_name:string,start_date:string,end_date:string,reminder_in_minutes:{id: number, name: string},repeat_event_in:{id: string, name: string},related_i2employee:{id: number, name: string}[],
    related_users_companies:{id: string, name: string}[],notes:string,size_event: {id: string, name: string}, is_socializing: boolean, is_advertising: boolean, is_persona_overlap: boolean, id?:number,repeat_id?:string,event_url?:string,rating:{social: number,list_buying: number,advertise: number},attachment?:{filename:string,uploaded:number,url:string}[]}){
        if(action === 'delete'){
            let body:{id: number, repeat_id?:string} = {id: eventData.id};
            if(eventData.repeat_id){
                body.repeat_id = eventData.repeat_id;
            }
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(body)
            };
            return this.http[action](`${this.url}/intranet/industry_event`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            let body: any = {
                start_date: eventData.start_date,
                end_date: eventData.end_date,
                event_name: eventData.event_name
            }
            if(eventData.id){
                body.id = eventData.id;
            }
            if(eventData.repeat_id){
                body.repeat_id = eventData.repeat_id;
            }
            if(eventData.reminder_in_minutes){
                body.reminder_in_minutes = eventData.reminder_in_minutes.id;
            }
            if(eventData.repeat_event_in){
                body.repeat_event_in = eventData.repeat_event_in.id;
            }
            if(eventData.related_i2employee && eventData.related_i2employee.length){
                body.related_i2employee = eventData.related_i2employee.map(i2emp => i2emp.id).join();
            }
            if(eventData.related_users_companies && eventData.related_users_companies.length){
                body.related_users_companies = eventData.related_users_companies.map(ruserscomp => ruserscomp.id).join();
            }
            if(eventData.notes){
                body.notes = eventData.notes;
            }
            if(eventData.event_url){
                body.event_url = eventData.event_url;
            }
            if(eventData.rating){
                ['advertise', 'list_buying', 'social'].forEach(property => {
                    if(eventData.rating[property] == null){
                        eventData.rating[property] = 0;
                    }
                });
                body.rating = eventData.rating; 
            }
            if(eventData.attachment){
                body.attachment = eventData.attachment
            }
            if(eventData.size_event && eventData.size_event.hasOwnProperty('id')){
                body.size_event = eventData.size_event.id;
            }
            if(typeof eventData.is_socializing === 'boolean'){
                body.is_socializing = eventData.is_socializing
            }
            if(typeof eventData.is_advertising === 'boolean'){
                body.is_advertising = eventData.is_advertising
            }
            if(typeof eventData.is_persona_overlap === 'boolean'){
                body.is_persona_overlap = eventData.is_persona_overlap
            }
            console.log('BEFORE SUBMIT DATA>>>', body)
            return this.http[action](`${this.url}/intranet/industry_event`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
        }
    }
    GetIndustryEventSizeOptions(){
        return this.http.get(`${this.url}/intranet/industry_event_size_options`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAllI2Employee(){
        return this.http.get(`${this.url}/intranet/industry_event_fwemployee_options`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAllRepeatEventOptions(){
        return this.http.get(`${this.url}/intranet/industry_event_repeat_options`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAllEventReminderOptions(){
        return this.http.get(`${this.url}/intranet/industry_event_reminder_options`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetSearchUsersCompaniesOptions(query: string){
        let Params = new HttpParams();
        Params = Params.append('Q', query);
        return this.http.get(`${this.url}/intranet/industry_event_userco_options`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    IndustryEventUpload(fileData: File[]){
        const body: FormData = new FormData();
        fileData.forEach(file => {
            body.append('upload', file, file.name);
        });
        return this.http.post(`${this.url}/intranet/ie_attachment_upload`, body, { reportProgress: true, observe: 'events', headers: this.headerSrv.returnHeader() }).pipe(
            map((event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                  case HttpEventType.Response:
                    return event.body;
                  default:
                    return { status: 'progress', message: event.type };
                }
              }),
            catchError(this.headerSrv._errorHandler)
        );
    }
    SearchIndustryEvents(startDate, endDate, event_name: string, iMV: {id: number, name: string}[],iAMV: {id: number, name: string}[], is_socializing: boolean, is_advertising: boolean, is_persona_overlap: boolean){
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        if(event_name){
            Params = Params.append('event_name', event_name);
        }
        if(iMV){
            Params = Params.append('iMV', iMV.map(mvid=>mvid.id).join());
        }
        if(iAMV){
            Params = Params.append('iAMV', iAMV.map(mvid=>mvid.id).join());
        }
        if(is_socializing){
            Params = Params.append('is_socializing', is_socializing.toString());
        }
        if(is_advertising){
            Params = Params.append('is_advertising', is_advertising.toString());
        }
        if(is_persona_overlap){
            Params = Params.append('is_persona_overlap', is_persona_overlap.toString());
        }
        return this.http.get(`${this.url}/intranet/industry_event`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AutoTagIndustryEventsNotes(notes: string) {
        return this.http.put(`${this.url}/intranet/autotag`, {notes: notes}, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllIndustryPages(){
        return this.http.get(`${this.url}/intranet/industry_pages`, {headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditIndustryPages(action: 'post'|'put'|'delete', pageBody:IndustryPage){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(pageBody)
            };
            return this.http[action](`${this.url}/intranet/industry_pages`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/intranet/industry_pages`, JSON.stringify(pageBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
}