
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HeaderService } from '../shared/header.service';
@Injectable()
export class IIStatManagerService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private headerSrv: HeaderService) { }

    getUsageAlert(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/iistatmanager/getUsageAlert`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }

}
