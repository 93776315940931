<footer class="footer">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            <span id="update-available" class="new-version faa-pulse animated faa-slow">New version is available</span>
            &copy; 1999 - {{test | date: 'yyyy'}} <i class="fa fa-lightbulb-o blue-bulb" matTooltip="Admin Version: {{verAdm}}"
            matTooltipPosition="above"></i> <a href="https://www.industryintel.com" target="_blank"> Industry Intelligence Inc.</a>  All rights reserved.
        </div>
    </div>
</footer>
