<router-outlet></router-outlet>
<!-- <div id="update-available">
    Looks like you're using an older version of our app.
    <div class="upd-button-wrapper">
        <button (click)="refresh()" type="button" class="btn btn-xs btn-success btn-fill btn-rotate btn-m-sm upd-button">
            <span class="btn-label">
                <i class="fa fa-refresh"></i>
            </span>
            Click to reload
        </button>
    </div>
</div> -->