import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AppRoutes } from './app.routing';
import { CookieModule } from 'ngx-cookie';
import { HeaderService, AuthGuardService, VersionCheckService } from '../app/services/index'
import { RoleGuardService } from '../app/services/shared/role-guard.service';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { SharedDirectivesModule } from './shared/exportshareddirectives.module';
import * as Sentry from "@sentry/browser";
import { environment } from '../environments/environment';
import { UploadInterceptor, LoaderInterceptor } from './services/interceptors';
import { MarkdownModule } from 'ngx-markdown';
console.log(`IS PRODUCTION MODE?: ${environment.production}`, environment)
if (environment.production) {
    Sentry.init({
        dsn: "https://d3ae2612835e4cdaa121f070ea554e65@sentry.io/1461454",
        environment: environment.mode,
        release: environment.verAdm,
        beforeBreadcrumb(breadcrumb, hint) {
            return breadcrumb.category === 'console' && breadcrumb.level === 'warning' ? null : breadcrumb;
        }
    });
}
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
        console.error('Big ERROR OCCURED!', error.originalError || error);
        // throw error;
    }
}
@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        CookieModule.forRoot(),
        SlimLoadingBarModule.forRoot(),
        SharedDirectivesModule,
        MarkdownModule.forRoot()
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent
    ],
    bootstrap: [AppComponent],
    providers: [HeaderService, AuthGuardService, VersionCheckService, RoleGuardService,
         { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
         { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true},
        { provide: ErrorHandler, useClass: SentryErrorHandler }]
})

export class AppModule { }
