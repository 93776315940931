import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FooterService, CommonService } from '../../services/index';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
@Component({

    selector: 'footer-cmp',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.css']
})

export class FooterComponent implements OnInit {
    test: Date = new Date();
    public getGreeting;
    public verAdm: string;
    constructor(private footService: FooterService, private cmmnSrv: CommonService,private cdr: ChangeDetectorRef) { }
    ngOnInit() {
        this.verAdm = environment.verAdm;
        const UserInfoSession = JSON.parse(localStorage.getItem('AdminUserInfo'));
        if (UserInfoSession) {
            const userName: string = UserInfoSession.Info.qUser[0].first_name;
            const greeting: string = 'Good ' + this.footService.getGreetingTime(moment()) + ', ' + userName + '!';
            this.getGreeting = greeting;
            this.cdr.detectChanges();
        }
    }
}
