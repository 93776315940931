<div class="logo adj-logo">
  <a routerLink="/dashboard" class="simple-text logo-normal">
    <div>
      <img class="adj-logo-img" src="assets/img/IndustryIntelLogoTrans.png" />
    </div>
  </a>
</div>

<div class="sidebar-wrapper">
  <div class="user">
    <div *ngIf="userInfo">
      <div *ngIf="userInfo.photo2" class="photo">
        <img [src]="userInfo.photo2" />
      </div>
      <div *ngIf="!userInfo.photo2" class="noImage">
        <ngx-avatar  textSizeRatio="2" [name]="userInfo.first_name + ' ' + userInfo.last_name" size="35" bgColor="#1dcaff" [round]="true">
        </ngx-avatar>
      </div>
    </div>
    <div class="info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed" aria-expanded="true">
        <span *ngIf="userInfo">
          {{ userInfo.first_name }} {{ userInfo.last_name }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>

      <div class="collapse in" id="collapseExample" aria-expanded="true">
        <ul class="nav">
          <!-- <li>
            <a href="#profile">
              <span class="sidebar-mini">
                <i class="fa fa-user-circle"></i>
              </span>
              <span class="sidebar-normal">My Profile</span>
            </a>
          </li>
          <li>
            <a href="#edit">
              <span class="sidebar-mini">Ep</span>
              <span class="sidebar-normal">Edit Profile</span>
            </a>
          </li>
          <li>
            <a href="#settings">
              <span class="sidebar-mini">
                <i class="fa fa-cog"></i>
              </span>
              <span class="sidebar-normal">Settings</span>
            </a>
          </li> -->
          <li>
            <a class="add-cursor" (click)="logout()">
              <span class="sidebar-mini">
                <i class="fa fa-sign-out"></i>
              </span>
              <span class="sidebar-normal">Log Out</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="isNotMobileMenu()">
    <form class="navbar-form navbar-left navbar-search-form" role="search">
      <div class="input-group">
        <span class="input-group-addon">
          <i class="fa fa-search"></i>
        </span>
        <input type="text" value="" class="form-control" placeholder="Search...">
      </div>
    </form>
    <ul class="nav nav-mobile-menu">
      <li>
        <a href="#stats" class="dropdown-toggle btn-magnify" data-toggle="dropdown">
          <i class="ti-panel"></i>
          <p>Stats</p>
        </a>
      </li>
      <li class="dropdown">
        <a href="#notifications" class="dropdown-toggle btn-rotate" data-toggle="dropdown">
          <i class="ti-bell"></i>
          <span class="notification">5</span>
          <p class="hidden-md hidden-lg">
            Notifications
            <b class="caret"></b>
          </p>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="#not1">Notification 1</a>
          </li>
          <li>
            <a href="#not2">Notification 2</a>
          </li>
          <li>
            <a href="#not3">Notification 3</a>
          </li>
          <li>
            <a href="#not4">Notification 4</a>
          </li>
          <li>
            <a href="#another">Another notification</a>
          </li>
        </ul>
      </li>
      <li>
        <a class="btn-rotate">
          <i class="ti-settings"></i>
          <p class="hidden-md hidden-lg">
            Settings
          </p>
        </a>
      </li>
    </ul>
  </div> -->
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
        <i class="{{ menuitem.icontype }}"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If is a single link && ExternalLink-->
      <a [href]="menuitem.path" target="_blank" *ngIf="menuitem.type === 'extlink'">
        <i class="{{ menuitem.icontype }}"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{ menuitem.title }}" *ngIf="menuitem.type === 'sub'">
        <i class="{{ menuitem.icontype }}"></i>
        <p>
          {{ menuitem.title }}
          <b class="caret"></b>
        </p>
      </a>

      <!--Display the submenu items-->
      <div id="{{ menuitem.title }}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
            <a
              class="add-cursor"
              (click)="routeSubmenu(childitem.expath, [menuitem.path, childitem.path], childitem.exlink)"
            >
              <span class="sidebar-mini">{{ childitem.ab }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
