import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../shared/auth.service';

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        if (request.url.includes('/serviceman/pdfupload')) {
            const adminUser = this.authenticationService.isValidToken();
            if (adminUser) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${adminUser}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}