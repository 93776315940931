import { Component, OnInit } from '@angular/core';
import { VersionCheckService } from './services/application/version-check.service';
import { environment } from 'environments/environment.prod';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(private versionCheckService: VersionCheckService) { }
  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.version);
  }
  refresh(): void {
    window.location.reload();
  }
}
