import { Injectable } from '@angular/core';
import { HeaderService } from '../shared/header.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { catchError, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class PageMonitorService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private _cookieService: CookieService, private headerSrv: HeaderService) { }

    GETANYTHINGWITHPARAM(section: string) {
        let Params = new HttpParams();
        Params = Params.append('section', section.toString());
        return this.http.get(`${this.url}/infoman/getall`, { params: Params, headers: this.headerSrv.returnHeader() })
    }
    GetAllPageMon(userIds: {id: number, name: string}[], categories: {name: string}[], tracking: {id: number, type: string}[]) {
        let Params = new HttpParams();
        if(userIds){
            Params = Params.append('userId', userIds.map(user=>user.id).join());
        }
        if(categories && categories.length){
            Params = Params.append('category', categories.map(type=>type.name).join('|'));
        }
        Params = Params.append('type', tracking.map(type=>type.id).join());
        return this.http.get(`${this.url}/infoman/mypagemon`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddPageMonURL(editor:{id: number, name: string}, categories: string[],url: string, checkEveryMin: number, republish_right:3712928|3712927, offlineLink?: boolean) {
        const body = new FormData();
        if(editor){
            body.append('userId', editor.id.toString());
        }
        if(categories && categories.length){
            body.append('category', JSON.stringify(categories));
        }
        body.append('republish_right', republish_right.toString());
        body.append('url', url);
        body.append('checkEveryMin', checkEveryMin.toString());
        if(offlineLink){
            body.append('offlineLink', 'true');
        }
        return this.http.post(`${this.url}/infoman/pagemon`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdatePageMonURL(editor:{id: number, name: string}, categories: string[],url: string, checkEveryMin: number, myPageMonId: number, republish_right:3712928|3712927, offlineLink: boolean) {
        const body = new FormData();
        if(editor){
            body.append('userId', editor.id.toString());
        }
        if(categories && categories.length){
            body.append('category', JSON.stringify(categories));
        }
        body.append('url', url);
        body.append('republish_right', republish_right.toString());
        body.append('checkEveryMin', checkEveryMin.toString());
        body.append('mypagemonId', myPageMonId.toString());
        if(offlineLink){
            body.append('offlineLink', offlineLink.toString());
        }
        return this.http.post(`${this.url}/infoman/pagemon`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeletePageMonURL(myPageMonId: number) {
        const body = new FormData();
        body.append('delete', 'true');
        body.append('mypagemonId', myPageMonId.toString());
        return this.http.post(`${this.url}/infoman/pagemon`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchPageMon(query: string){
        let Params = new HttpParams();
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/infoman/pagemonsuggest`, { params: Params, headers: this.headerSrv.returnHeader() })
    }
    GetPageMonitorUsers(){
        return this.http.get(`${this.url}/infoman/pagemonusers`, { headers: this.headerSrv.returnHeader() })
    }
    SubmitTransferToBigAgg(mv_id:{id: number, name: string},pageMonId: number, tags?:{id: number, name: string}[]){
        const body = new FormData();
        body.append('mv_id', mv_id.id.toString());
        body.append('id', pageMonId.toString());
        if(tags && tags.length){
            body.append('tags', tags.map(tItem=>tItem.id).join());
        }
        return this.http.post(`${this.url}/infoman/Transfer2BiAgg`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
}
