
import {throwError as observableThrowError} from 'rxjs';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
@Injectable()
export class HeaderService {
    public url = environment.urlpath;
    private token: string;
    public version = '1.0.83';
    constructor(private http: HttpClient, private router: Router, private _cookieService: CookieService) { }

    returnHeader() {
        let currentUser = { token: '' };
        const UserInfo = localStorage.getItem('AdminUserInfo');
        if (UserInfo === null) {
            const LoginTokenVal = this._cookieService.get('AdminLoginToken');
            if (LoginTokenVal !== null) {
                currentUser.token = LoginTokenVal;
            };
        } else {
            currentUser = JSON.parse(UserInfo);
        };
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + currentUser.token });
        return headers;
    }
    _errorHandler(error: Response) {
        return observableThrowError(error || 'Server Error on Server Occured');
    }
}
