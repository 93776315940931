import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad } from '@angular/router';
import { AuthenticationService } from './auth.service';
declare var swal: any;
@Injectable()
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(public auth: AuthenticationService, public router: Router) { }
  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['pages/login']);
      return false;
    }
  }
  canLoad(): boolean {
    if (this.auth.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['pages/login']);
      return false;
    }
  }
}
