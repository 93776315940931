import { Injectable } from '@angular/core';
import { HeaderService } from '../shared/header.service';
import { HttpClient, HttpParams, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { catchError, delay, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, forkJoin, throwError } from 'rxjs';
@Injectable()
export class InfoManService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private _cookieService: CookieService, private headerSrv: HeaderService) { }

    async CreateEditorJWToken(environmentId: string, secretKey: string, adminInfo: { userId: number, userEmail: string, userName: string }) {
        let random = { gender: Math.floor(Math.random() * 2) ? 'men' : 'women', number: Math.floor(Math.random() * 100) + 1 }
        const payload = {
            iss: environmentId,
            iat: 1511963669,
            user: {
                id: adminInfo.userId,
                email: adminInfo.userEmail,
                name: adminInfo.userName,
                avatar: `https://randomuser.me/api/portraits/thumb/${random.gender}/${random.number}.jpg`
            },
            services: {
                'ckeditor-collaboration': {
                    permissions: {
                        '*': "write"
                    }
                }
            }
        }
        const body = new FormData();
        body.append('payload', JSON.stringify(payload))
        body.append('secretKey', secretKey);
        const ayncResult = await this.http.post(`${this.url}/infoman/tokengen`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
        return ayncResult;
    }
    GetAllInfoManInitial(section: string, pagesize: number, startFrom: number, sortOrder: number, sortField: string, ActiveEditor: { id: number, name: string }): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('section', section);
        Params = Params.append('pagesize', pagesize.toString());
        Params = Params.append('startFrom', startFrom.toString());
        Params = Params.append('sortOrder', sortOrder.toString());
        Params = Params.append('sortField', sortField);
        if (ActiveEditor) {
            Params = Params.append('author', ActiveEditor.id.toString());
        }
        if (section === 'draft-feed') {
            return this.http.get(`${this.url}/infoman/getallfeed`, { params: Params, headers: this.headerSrv.returnHeader() })
                .pipe(map((resp: any) => { resp.getall = resp.getallfeed; delete resp.getallfeed; return resp; }), catchError(this.headerSrv._errorHandler));
        } else {
            return this.http.get(`${this.url}/infoman/getall`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
        }
    }
    // ADDING REALTIME
    LazyLoadMainItems(section: string, pagesize: number, startFrom: number, sortOrder: number, sortField: string, globalFilter: string, filters: {
        block: { value: { id: number, name: string }[], mathMode: string },
        type: { value: { id: number, name: string }[], mathMode: string },
        feed: { value: { id: number, name: string }[], mathMode: string },
        author: { value: { id: number, name: string }[], mathMode: string },
        to: { value: { id: number, name: string }[], mathMode: string },
        title: { value: string, mathMode: string },
        source: { value: string, mathMode: string }
    }): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('section', section.toString());
        Params = Params.append('pagesize', pagesize.toString());
        Params = Params.append('startFrom', startFrom.toString());
        Params = Params.append('sortOrder', sortOrder.toString());
        Params = Params.append('sortField', sortField);
        if (globalFilter) { Params = Params.append('globalFilter', globalFilter); }
        if (filters) {
            if (filters.hasOwnProperty('block')) {
                Params = Params.append('block', filters.block.value.map(block => block.id).join());
            }
            if (filters.hasOwnProperty('type')) {
                Params = Params.append('type', filters.type.value.map(type => type.id).join());
            }
            if (filters.hasOwnProperty('author')) {
                Params = Params.append('author', filters.author.value.map(author => author.id).join());
            }
            if (filters.hasOwnProperty('to')) {
                Params = Params.append('assignToEditor', filters.to.value.map(assignTo => assignTo.id).join());
            }
            if (filters.hasOwnProperty('feed')) {
                Params = Params.append('feed', filters.feed.value.map(feed => feed.id).join());
            }
            if (filters.hasOwnProperty('title') && section === 'draft-feed') {
                Params = Params.append('title', filters.title.value);
            }
            if (filters.hasOwnProperty('source') && section === 'draft-feed') {
                Params = Params.append('source', filters.source.value);
            }
        }
        if (section === 'draft-feed') {
            return this.http.get(`${this.url}/infoman/getallfeed`, { params: Params, headers: this.headerSrv.returnHeader() })
                .pipe(map((resp: any) => { resp.getall = resp.getallfeed; delete resp.getallfeed; return resp; }), catchError(this.headerSrv._errorHandler));
        } else {
            return this.http.get(`${this.url}/infoman/getall`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
        }
    }
    GetStoryById(articleId: string, keepDefaultDate?: string) {
        const body = new FormData();
        body.append('id', articleId);
        if(keepDefaultDate){
            body.append('keepDefaultDate', keepDefaultDate);
        }
        return this.http.post(`${this.url}/infoman/getstory`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBlankStory() {
        return this.http.get(`${this.url}/infoman/getstory`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMetaValueQByMetaTypeId(metaTypeId: number, metaValueId: number, skipX?: boolean) {
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', metaTypeId.toString());
        if (metaValueId) {
            Params = Params.append('id', metaValueId.toString());
        }
        if (skipX && skipX === true) {
            Params = Params.append('skipX', 'true');
        }
        return this.http.get(`${this.url}/infoman/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() })
            .pipe(map((resp: any) => { if (resp.metavalueQ.data && metaValueId) { resp.metavalueQ.data.metaValueId = metaValueId }; return resp; }), catchError(this.headerSrv._errorHandler));
    }
    GetMetaValueQByMetaTypeIdSearch(metaTypeId: number, selectedIds: number[]) {
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', metaTypeId.toString());
        if (metaTypeId === 8 || metaTypeId === 9) {
            Params = Params.append('skipx', 'true');
        }
        return this.http.get(`${this.url}/infoman/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() })
            .pipe(map((resp: any) => { if (resp.metavalueQ.data && selectedIds) { resp.metavalueQ.data.selectedIds = selectedIds }; return resp; }), catchError(this.headerSrv._errorHandler));
    }
    SearchMetaValueQ(metaTypeId: number | number[], metaValueId: number, query: string, skipX?: boolean) {
        const body = new FormData();
        if(typeof metaTypeId === 'number'){
            body.append('metaTypeId', metaTypeId.toString());
        }else{
            body.append('metaTypeId', metaTypeId.join());
        }
        if (metaValueId) {
            body.append('id', metaValueId.toString());
        }
        body.append('q', query);
        if (skipX && skipX === true) {
            body.append('skipX', 'true');
        }
        return this.http.post(`${this.url}/infoman/metavalueq`, body, { headers: this.headerSrv.returnHeader() })
            .pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchMetaValueQByMetaType(metaTypeId: number|number[],query: string,pagemon?:boolean) {
        let Params = new HttpParams();
        if(typeof metaTypeId === 'number'){
            Params = Params.append('metaTypeId', metaTypeId.toString());
        }else{
            Params = Params.append('metaTypeId', metaTypeId.join());
        }
        Params = Params.append('q', query);
        Params = Params.append('skipX', 'true');
        if(pagemon){
            Params = Params.append('pagemon', pagemon.toString());
        }
        return this.http.get(`${this.url}/infoman/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchCompanyValueQ(metaTypeId: number, query: string) {
        const body = new FormData();
        body.append('metaTypeId', metaTypeId.toString());
        body.append('q', query);
        return this.http.post(`${this.url}/infoman/companyvalueQ`, body, { headers: this.headerSrv.returnHeader() })
            .pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchCompanyBrandValueQ(metaTypeId: number, query: string) {
        const body = new FormData();
        body.append('metaTypeId', metaTypeId.toString());
        body.append('q', query);
        return this.http.post(`${this.url}/infoman/brandvalueQ`, body, { headers: this.headerSrv.returnHeader() })
            .pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchStorySource(sourceQuery: string) {
        let Params = new HttpParams();
        Params = Params.append('q', sourceQuery);
        return this.http.get(`${this.url}/infoman/getSourceName`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetNewsPriority(selectedBindingArray: [number, [number, number]][], xdocId: number) {
        let Params = new HttpParams();
        for (let i = 0; i < selectedBindingArray.length; i++) {
            const typeArray = selectedBindingArray[i];
            Params = Params.append(`mt${typeArray[0]}`, typeArray[1].toString());
        }
        if (xdocId) {
            Params = Params.append('excludeXDoc', xdocId.toString());
        }
        return this.http.get(`${this.url}/infoman/getNewsPriority`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    Get360RelatedNews(selectedBindingArray: [number, [number, number]][], xdocId: number) {
        let Params = new HttpParams();
        for (let i = 0; i < selectedBindingArray.length; i++) {
            const typeArray = selectedBindingArray[i];
            Params = Params.append(`mt${typeArray[0]}`, typeArray[1].toString());
        }
        if (xdocId) {
            Params = Params.append('excludeXDoc', xdocId.toString());
        }
        return this.http.get(`${this.url}/infoman/get360`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLazy360RelatedNews(urlQueryString) {
        return this.http.get(`${this.url}/infoman/get360?${urlQueryString}`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddCreateMetaValue(metaTypeId: number, ValuesToAdd: string[]) {
        let body = { metaTypeId: metaTypeId, name: ValuesToAdd };
        return this.http.post(`${this.url}/infoman/addvalue`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddArticleToInfoMan(title: string, content: string, channel: string, event: string) {
        const body = new FormData();
        body.append('title', title);
        body.append('content', content);
        body.append('channel', channel);
        body.append('event', event);
        return this.http.post(`${this.url}/infoman/sendmessage`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetArticleUsage(articleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        return this.http.get(`${this.url}/infoman/getusage2`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    LazyArticleUsage(articleId: number, startRow: number, rowPerPage: number, rowNum: number, sortfield: string, sortOrder: number, searchRow: string, listKey: string) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        Params = Params.append('rowPerPage', rowPerPage.toString());
        Params = Params.append('rowNum', rowNum.toString());
        Params = Params.append('sortfield', sortfield);
        Params = Params.append('sortorder', sortOrder.toString());
        Params = Params.append('startRow', startRow.toString());
        if (searchRow) {
            Params = Params.append('searchRow', searchRow.toString());
        }
        Params = Params.append('listKey', listKey.toString());
        return this.http.get(`${this.url}/infoman/getusage`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    GetArticleRelatedReport(articleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString());
        return this.http.get(`${this.url}/infoman/getrelatedreport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    InfoManSearch(searchParams: {
        startDate: string, endDate: string, docType: number[], docStatus: number[], iMv: number[], iAmv: number[], oMv: number[], iMVGroup:{type: string, data: {id: number, name: string, type: {id: number, name: string} }[] }[],
        iAMVGroup:{type: string, data: {id: number, name: string, type: {id: number, name: string} }[] }[], oMVGroup:{type: string, data: {id: number, name: string, type: {id: number, name: string} }[] }[],
        editorId: number[], aux: number[], q: string, cxs: string, qType: number[], exSearchQ: string, looseSearch: boolean, alfred_pool: boolean, usage?:boolean
    }, isMap: boolean, isUsage: boolean, isImages: boolean) {
        const body = new FormData();
        body.append('startDate', searchParams.startDate);
        if (searchParams.endDate && searchParams.endDate !== "Invalid date") {
            body.append('endDate', searchParams.endDate);
        }
        if (searchParams.docType) {
            body.append('docType', searchParams.docType.join());
        }
        if (searchParams.docStatus) {
            body.append('docStatus', searchParams.docStatus.join());
        }
        if (searchParams.iMv) {
            body.append('iMv', searchParams.iMv.join());
        }
        if (searchParams.iAmv) {
            body.append('iAmv', searchParams.iAmv.join());
        }
        if (searchParams.oMv) {
            body.append('oMv', searchParams.oMv.join());
        }
        if (searchParams.iMVGroup) {
            body.append('iMVGroup', JSON.stringify(searchParams.iMVGroup));
        }
        if (searchParams.iAMVGroup) {
            body.append('iAMVGroup', JSON.stringify(searchParams.iAMVGroup));
        }
        if (searchParams.oMVGroup) {
            body.append('oMVGroup', JSON.stringify(searchParams.oMVGroup));
        }
        if (searchParams.editorId) {
            body.append('editorId', searchParams.editorId.join());
        }
        if (searchParams.aux) {
            body.append('aux', searchParams.aux.join());
        }
        if (searchParams.qType) {
            body.append('qType', searchParams.qType.join());
        }
        if (searchParams.q) {
            body.append('q', searchParams.q);
        }
        if (searchParams.cxs) {
            body.append('cxs', searchParams.cxs);
        }
        if (searchParams.exSearchQ) {
            body.append('exSearchQ', searchParams.exSearchQ);
        }
        if (searchParams.looseSearch) {
            body.append('looseSearch', searchParams.looseSearch.toString());
        } else {
            body.append('looseSearch', 'false');
        }
        if (searchParams.alfred_pool) {
            body.append('alfred_pool', searchParams.alfred_pool.toString());
        } else {
            body.append('alfred_pool', 'false');
        }
        body.append('map', isMap.toString());
        body.append('usage', isUsage.toString());
        body.append('images', isImages.toString());
        if(isUsage){
            return this.http.post(`${this.url}/infoman/search`, body, { headers: this.headerSrv.returnHeader() })
        } else {
            return this.http.post(`${this.url}/infoman/search`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler)); 
        }
    }
    InfoManSearchExcel(searchParams: {
        startDate: string, endDate: string, docType: number[], docStatus: number[], iMv: number[], iAmv: number[], oMv: number[],iMVGroup:{type: string, data: {id: number, name: string, type: {id: number, name: string} }[] }[],
        iAMVGroup:{type: string, data: {id: number, name: string, type: {id: number, name: string} }[] }[], oMVGroup:{type: string, data: {id: number, name: string, type: {id: number, name: string} }[] }[],
        editorId: number[],aux: number[], q: string, qType: number[], exSearchQ: string, looseSearch: boolean, alfred_pool: boolean
    }) {
        const body = new FormData();
        body.append('startDate', searchParams.startDate);
        if (searchParams.endDate && searchParams.endDate !== "Invalid date") {
            body.append('endDate', searchParams.endDate);
        }
        if (searchParams.docType) {
            body.append('docType', searchParams.docType.join());
        }
        if (searchParams.docStatus) {
            body.append('docStatus', searchParams.docStatus.join());
        }
        if (searchParams.iMv) {
            body.append('iMv', searchParams.iMv.join());
        }
        if (searchParams.iAmv) {
            body.append('iAmv', searchParams.iAmv.join());
        }
        if (searchParams.oMv) {
            body.append('oMv', searchParams.oMv.join());
        }
        if (searchParams.iMVGroup) {
            body.append('iMVGroup', JSON.stringify(searchParams.iMVGroup));
        }
        if (searchParams.iAMVGroup) {
            body.append('iAMVGroup', JSON.stringify(searchParams.iAMVGroup));
        }
        if (searchParams.oMVGroup) {
            body.append('oMVGroup', JSON.stringify(searchParams.oMVGroup));
        }
        if (searchParams.editorId) {
            body.append('editorId', searchParams.editorId.join());
        }
        if (searchParams.aux) {
            body.append('aux', searchParams.aux.join());
        }
        if (searchParams.qType) {
            body.append('qType', searchParams.qType.join());
        }
        if (searchParams.q) {
            body.append('q', searchParams.q);
        }
        if (searchParams.exSearchQ) {
            body.append('exSearchQ', searchParams.exSearchQ);
        }
        if (searchParams.looseSearch) {
            body.append('looseSearch', searchParams.looseSearch.toString());
        } else {
            body.append('looseSearch', 'false');
        }
        if (searchParams.alfred_pool) {
            body.append('alfred_pool', searchParams.alfred_pool.toString());
        } else {
            body.append('alfred_pool', 'false');
        }
        body.append('map', 'true');
        body.append('excel', 'true');
        return this.http.post(`${this.url}/infoman/search`, body, { headers: this.headerSrv.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UsageSearch(searchParams: {
        startDate: string, endDate: string, docType: number[], docStatus: number[], iMv: number[], iAmv: number[], oMv: number[], editorId: number[],
        aux: number[], q: string, qType: number[], exSearchQ: string, looseSearch: boolean, alfred_pool: boolean
    }) {
        const body = new FormData();
        body.append('startDate', searchParams.startDate);
        if (searchParams.endDate && searchParams.endDate !== "Invalid date") {
            body.append('endDate', searchParams.endDate);
        }
        if (searchParams.docType) {
            body.append('docType', searchParams.docType.join());
        }
        if (searchParams.docStatus) {
            body.append('docStatus', searchParams.docStatus.join());
        }
        if (searchParams.iMv) {
            body.append('iMv', searchParams.iMv.join());
        }
        if (searchParams.iAmv) {
            body.append('iAmv', searchParams.iAmv.join());
        }
        if (searchParams.oMv) {
            body.append('oMv', searchParams.oMv.join());
        }
        if (searchParams.editorId) {
            body.append('editorId', searchParams.editorId.join());
        }
        if (searchParams.aux) {
            body.append('aux', searchParams.aux.join());
        }
        if (searchParams.qType) {
            body.append('qType', searchParams.qType.join());
        }
        if (searchParams.q) {
            body.append('q', searchParams.q);
        }
        if (searchParams.exSearchQ) {
            body.append('exSearchQ', searchParams.exSearchQ);
        }
        if (searchParams.looseSearch) {
            body.append('looseSearch', searchParams.looseSearch.toString());
        } else {
            body.append('looseSearch', 'false');
        }
        if (searchParams.alfred_pool) {
            body.append('alfred_pool', searchParams.alfred_pool.toString());
        } else {
            body.append('alfred_pool', 'false');
        }
        return this.http.post(`${this.url}/infoman/UsageSearch`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    LazyInfoManSearch(searchId: string, searchSize: number, searchFrom: number, globalFilter: string) {
        const body = new FormData();
        body.append('searchId', searchId);
        body.append('searchSize', searchSize.toString());
        body.append('searchFrom', searchFrom.toString());
        if (globalFilter) {
            body.append('searchQ', globalFilter);
        }
        return this.http.post(`${this.url}/infoman/search`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    LazyInfoManUsageSearch(searchId: string, searchSize: number, searchFrom: number, globalFilter: string) {
        const body = new FormData();
        if (searchId) {
            body.append('searchId', searchId);
        }
        body.append('searchSize', searchSize.toString());
        body.append('searchFrom', searchFrom.toString());
        if (globalFilter) {
            body.append('searchQ', globalFilter);
        }
        return this.http.post(`${this.url}/infoman/UsageSearch`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateArticle(articleBody) {
        return this.http.put(`${this.url}/infoman/savestory`, JSON.stringify(articleBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AutoTagArticle(articleBody) {
        return this.http.put(`${this.url}/infoman/autotag`, JSON.stringify(articleBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteDraftArticles(articleIds:number[]) {
        let Params = new HttpParams();
        Params = Params.append('idlist', articleIds.join());
        return this.http.get(`${this.url}/infoman/removeall`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteArticleById(articleBody) {
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify(articleBody)
        };
        return this.http.delete(`${this.url}/infoman/savestory`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    SaveArticle(articleBody) {
        return this.http.post(`${this.url}/infoman/savestory`, JSON.stringify(articleBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMetaValueById(mvType, metaValuesIdsString: string) {
        let Params = new HttpParams();
        Params = Params.append('id', metaValuesIdsString);
        return this.http.get(`${this.url}/infoman/metavalueId`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            map((resp: any) => { if (resp.metavalueId.data && mvType) { resp.metavalueId.mvType = mvType }; return resp; }),
            catchError(this.headerSrv._errorHandler));
    }
    GetMetaValueByGroup(index, mvType, metaValuesIdsString: string) {
        let Params = new HttpParams();
        Params = Params.append('id', metaValuesIdsString);
        return this.http.get(`${this.url}/infoman/metavalueId`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            map((resp: any) => { if (resp.metavalueId.data && mvType) {resp.metavalueId.index = index;resp.metavalueId.type = mvType;
                 resp.metavalueId.data = resp.metavalueId.data.map((mvItem) => { return { id: mvItem.id, name: mvItem.name, type: mvItem.type } });
             }; return resp; }),
            catchError(this.headerSrv._errorHandler));
    }
    SaveSearch(searchParams) {
        const body = searchParams;
        return this.http.post(`${this.url}/infoman/searchSave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RemoveSavedSearch(savedSearchData: { created: string, last_check: string, title: string, last_check_rowcount: number, id: number }[]) {
        let Params = new HttpParams();
        Params = Params.append('id', savedSearchData.map(sdata=>sdata.id).join());
        const httpOptions = {
            params: Params,
            headers: this.headerSrv.returnHeader()
        };
        return this.http.delete(`${this.url}/infoman/removeSearchSave`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAllMySearch() {
        return this.http.get(`${this.url}/infoman/getsearch`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetSearchById(searchId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', searchId.toString());
        return this.http.get(`${this.url}/infoman/search`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetStoryClobData(storyId) {
        let body = new FormData();
        if (typeof storyId === 'string') {
            body.append('id', storyId);
        } else {
            body.append('id', storyId.toString());
        }
        return this.http.post(`${this.url}/user/getStoryClob`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddDraftToAssignmentPool(action: 'update' | 'assign', articleId: number, message: string, priority: number, assignTo: number) {
        const body = new FormData();
        if (action === 'assign') {
            body.append('message', message);
            body.append('priority', priority.toString());
            body.append('assignTo', assignTo.toString());
        } else if (action === 'update') {
            body.append('message', message);
            body.append('priority', priority.toString());
        }
        body.append('act', action);
        body.append('id', articleId.toString());
        return this.http.post(`${this.url}/infoman/assign`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RemoveFromAssignmentPool(articleId: number) {
        const body = new FormData();
        body.append('act', 'remove');
        body.append('id', articleId.toString());
        return this.http.post(`${this.url}/infoman/assign`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async GetAllEditors() {
        return await this.http.get(`${this.url}/infoman/geteditors`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    async GetAllPagemonCategories() {
        return await this.http.get(`${this.url}/infoman/pagemon_categories`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    GetAllEditorsForFilters(): Observable<any> {
        return this.http.get(`${this.url}/infoman/geteditors`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async GetAllEditorsPromise(): Promise<any> {
        return await this.http.get(`${this.url}/infoman/geteditors`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    GetOverallUsage(startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/getUsageOverall`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetUsageHeatMap(startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/getUsageMap`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetPublishOverall(startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/getPublishOverall`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetTop100(startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/top100`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMostReportedStory(startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/mostreportstory`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetZeroEngagement(startDate: string, endDate: string) {
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/zeroreportstory`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMapReportFromStory(storyId: number){
        let Params = new HttpParams();
        Params = Params.append('id', storyId.toString());
        return this.http.get(`${this.url}/infoman/getmapreportfromstory`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));   
    }
    GetVisitorCountByVisitorId(visitorId: string){
        let Params = new HttpParams();
        Params = Params.append('visitorId', visitorId);
        return this.http.get(`${this.url}/infoman/getvisitors`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));   
    }
    GetAllDataTableFilters() {
        let featureDescriptionObsvs: Promise<any>[] = [];
        featureDescriptionObsvs.push(this.GetMetaValueQByMetaTypeId(1, null, true).pipe(map((resp) => { return resp.metavalueQ.data.typeData }),
            catchError(this.headerSrv._errorHandler)).toPromise());
        featureDescriptionObsvs.push(this.GetMetaValueQByMetaTypeId(9, null, true).pipe(map((resp) => { return resp.metavalueQ.data.typeData }),
            catchError(this.headerSrv._errorHandler)).toPromise());
        featureDescriptionObsvs.push(this.GetAllEditorsForFilters().pipe(map((resp) => { return resp.geteditors.data }),
            catchError(this.headerSrv._errorHandler)).toPromise());
        featureDescriptionObsvs.push(this.GetActiveNewsEdgeRss().pipe(map((resp: any) => { return resp.getnewsedgerss.data }),
            catchError(this.headerSrv._errorHandler)).toPromise());
        return forkJoin(featureDescriptionObsvs)
    }
    GetEditorsByIds(editorIds) {
        let Params = new HttpParams();
        Params = Params.append('all', 'true');
        if (Array.isArray(editorIds)) {
            if (editorIds.length) {
                Params = Params.append('editorId', editorIds.join());
            } else {
                // if array length is none send as -1 so that there is a blank data
                Params = Params.append('editorId', '-1');
            }
        } else {
            Params = Params.append('editorId', editorIds);
        }
        return this.http.get(`${this.url}/infoman/getEditors`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchEditors(query: string) {
        let Params = new HttpParams();
        Params = Params.append('all', 'true');
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/infoman/getEditors`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeRss(userId: number) {
        let Params = new HttpParams();
        if(userId){
            Params = Params.append('userId', userId.toString());
        }
        return this.http.get(`${this.url}/infoman/getNewsEdgeRss`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetActiveNewsEdgeRss() {
        let Params = new HttpParams();
        Params = Params.append('active', 'true');
        return this.http.get(`${this.url}/infoman/getNewsEdgeRss`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeRssById(id: number) {
        let Params = new HttpParams();
        Params = Params.append('id', id.toString());
        return this.http.get(`${this.url}/infoman/getNewsEdgeRss`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddNewsEdgeRss(newsEdgeData) {
        const body = newsEdgeData;
        return this.http.post(`${this.url}/infoman/saveNewsEdgeRss`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveNewsEdgeRss(newsEdgeData) {
        const body = newsEdgeData;
        return this.http.put(`${this.url}/infoman/saveNewsEdgeRss`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteNewsEdgeById(feedId: number) {
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({ id: feedId, infoStruct: {} })
        };
        return this.http.delete(`${this.url}/infoman/saveNewsEdgeRss`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRSSFeedPreview(feedURL: string) {
        let Params = new HttpParams();
        Params = Params.append('url', feedURL);
        return this.http.get(`${this.url}/infoman/previewrss`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ReportFeedThumbDown(section: string, pagesize: number, startFrom: number, sortOrder: number, sortField: string, thumbtype: number[], thumbid: string) {
        let Params = new HttpParams();
        // Params = Params.append('section', section);
        Params = Params.append('pagesize', pagesize.toString());
        Params = Params.append('startFrom', startFrom.toString());
        Params = Params.append('sortOrder', sortOrder.toString());
        Params = Params.append('sortField', sortField);
        Params = Params.append('thumbtype', thumbtype.join());
        Params = Params.append('thumbid', thumbid);
        return this.http.get(`${this.url}/infoman/feedthumbdown`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TransferFeedToMyDraft(feedId: string) {
        let Params = new HttpParams();
        Params = Params.append('id', feedId);
        return this.http.get(`${this.url}/infoman/transfer2draft`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetThumbsDownFeedData(rssId: number) {
        let Params = new HttpParams();
        Params = Params.append('rssId', rssId.toString());
        return this.http.get(`${this.url}/infoman/getfeedthumbdown`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ClearDraftFeedCache(cacheId: string) {
        let Params = new HttpParams();
        Params = Params.append('cacheId', cacheId);
        return this.http.get(`${this.url}/infoman/refreshCache`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAllThumbsDownFeedData() {
        return this.http.get(`${this.url}/infoman/getallfeedthumbsdown`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeRssUsage(getMinDate: boolean, startDate: string, endDate: string) {
        let Params = new HttpParams();
        if (getMinDate) {
            Params = Params.append('getmindate', getMinDate.toString());
        } else {
            Params = Params.append('startdate', startDate);
            Params = Params.append('enddate', endDate);
        }
        return this.http.get(`${this.url}/infoman/newsedgerssusage`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeRssUsageWeekly() {
        return this.http.get(`${this.url}/infoman/newsedgerssusageweekly`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    async PusherActiveEditorsWebhook(channelId: string, userId: number, isAddOrRemove: string, userIdList: number[]) {
        const body = new FormData();
        body.append('act', isAddOrRemove);
        if (isAddOrRemove === 'update') {
            body.append('userIdList', userIdList.join());
        } else {
            body.append('userId', userId.toString());
        }
        body.append('channel', channelId);
        return await this.http.post(`${this.url}/infoman/activeeditors`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler)).toPromise();
    }
    GetContentMarketData(action: string, articleId) {
        let Params = new HttpParams();
        if (action === 'add' && articleId) {
            Params = Params.append('parentArticleId', articleId.toString());
        } else if ((action === 'edit' && articleId)) {
            Params = Params.append('cmArticleId', articleId.toString());
        }
        Params = Params.append('act', action);
        return this.http.get(`${this.url}/infoman/getcontentmarketdata`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    DeleteContentMarketArticle(contentMarketArticleId: number) {
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({ "cmArticleId": contentMarketArticleId })
        };
        return this.http.delete(`${this.url}/infoman/savecontentmarketstory`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    UpdateContentMarketArticle(cmArticleId: number, cmType: { id: number, name: string }, cmAbout: { id: number, name: string }, cmKeyword: { id: number, name: string }[],
        cmBody: string, cmHeadline?: string, cmContextDate?: string, parentArticleId?: number) {
        let articleBody: {
            cmArticleId: number,
            cmType: number,
            cmAbout: number,
            cmKeyword: string,
            cmBody: string,
            cmHeadline?: string,
            cmContextDate?: string,
            parentArticleId?: number
        } = {
            cmArticleId: cmArticleId,
            cmType: cmType.id,
            cmAbout: cmAbout.id,
            cmKeyword: cmKeyword.map(keywordItem => keywordItem.id).join(),
            cmBody: cmBody
        }
        if (cmHeadline) {
            articleBody.cmHeadline = cmHeadline;
        }
        if (cmContextDate) {
            articleBody.cmContextDate = cmContextDate;
        }
        if (parentArticleId) {
            articleBody.parentArticleId = parentArticleId;
        }

        return this.http.put(`${this.url}/infoman/savecontentmarketstory`, JSON.stringify(articleBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddContentMarketArticle(cmType: { id: number, name: string }, cmAbout: { id: number, name: string }, cmKeyword: { id: number, name: string }[], cmBody: string, cmHeadline?: string, parentArticleId?: number) {
        const body = new FormData();
        body.append('cmType', cmType.id.toString());
        body.append('cmAbout', cmAbout.id.toString());
        body.append('cmKeyword', cmKeyword.map(keywordItem => keywordItem.id).join());
        body.append('cmBody', cmBody);
        if (cmHeadline) {
            body.append('cmHeadline', cmHeadline);
        }
        if (parentArticleId) {
            body.append('parentArticleId', parentArticleId.toString());
        }
        return this.http.post(`${this.url}/infoman/savecontentmarketstory`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CreatePublicLink(articleId: number, act: string) {
        const body = new FormData();
        body.append('id', articleId.toString());
        body.append('act', act);
        return this.http.post(`${this.url}/infoman/CreatePublicStory`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostToTwitter(articleId: number) {
        const body = new FormData();
        body.append('cmArticleId', articleId.toString());
        return this.http.post(`${this.url}/infoman/posttwitter`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PreviewTweetById(twitterId: number) {
        let Params = new HttpParams();
        Params = Params.append('twitterId', twitterId.toString());
        return this.http.get(`${this.url}/infoman/previewtweetbyid`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    ReassignEditorToArticle(articleId: number, editorId: number) {
        const body = new FormData();
        body.append('articleId', articleId.toString());
        body.append('editorId', editorId.toString());
        return this.http.post(`${this.url}/infoman/reassigneditortoarticle`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadPodcastAudio(fileData: File, podcastDuration: number) {
        const body: FormData = new FormData();
        body.append('fileData', fileData, fileData.name);
        body.append('duration', podcastDuration.toString());
        return this.http.post(`${this.url}/infoman/AudioUpload`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadPDF(fileData: File) {
        const body: FormData = new FormData();
        body.append('upload', fileData, fileData.name);
        return this.http.post(`${this.url}/infoman/pdfupload`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UploadPDFEditor(fileData: File[]) {
        const body: FormData = new FormData();
        fileData.forEach(file => {
            body.append('upload', file, file.name);
        });
        return this.http.post(`${this.url}/infoman/pdfupload2`, body, { reportProgress: true, observe: 'events', headers: this.headerSrv.returnHeader() }).pipe(
            map((event) => {
                switch (event.type) {
                  case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return { status: 'progress', message: progress };
                  case HttpEventType.Response:
                    return event.body;
                  default:
                    return { status: 'progress', message: event.type };
                }
              }),
            catchError(this.headerSrv._errorHandler)
        );
    }
    UploadImage(fileData: File){
        const body: FormData = new FormData();
        body.append('upload', fileData, fileData.name);
        return this.http.post(`${this.url}/infoman/ImageUpload`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ExportUsageToExcel(articleId: number){
        const body: FormData = new FormData();
        body.append('id', articleId.toString());
        return this.http.post(`${this.url}/infoman/getusageexport2`, body, { headers: this.headerSrv.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllCoClasses(){
        return this.http.get(`${this.url}/infoman/coclassset`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetClassforCompanies(companyList: {id: number,name: string}[]){
        let Params = new HttpParams();
        Params = Params.append('companyList', companyList.map(comp=>comp.id).join());
        return this.http.get(`${this.url}/infoman/coclass`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetClassforCompaniesBrand(companyList: {id: number,name: string}[]){
        let Params = new HttpParams();
        Params = Params.append('brandlist', companyList.map(comp=>comp.id).join());
        return this.http.get(`${this.url}/infoman/brandclass`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SaveCompaniesBrandClass(companyId: number, brandId: number, process: "Add" | "Delete") {
        const body = new FormData();
        body.append('companyId', companyId.toString());
        body.append('brandId', brandId.toString());
        body.append('process', process);
        return this.http.post(`${this.url}/infoman/brandclassset`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveCompanyBrandAssociations(body) {
        return this.http.post(`${this.url}/infoman/brandclassset`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveCompanyAssociations(body) {
        return this.http.post(`${this.url}/infoman/coclasssave`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetEventType(){
        return this.http.get(`${this.url}/infoman/geteventtype`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchEvents(searchEventsBody) {
        // {“company”: array of object,“EventType”: array of object,“About”: array of object,“Affect”: array of object,
        //  “Keyword”: array of object,“Country”: array of object,“State”: array of object,“FromDate”: string (ISO Date),
        // “ToDate”: string (ISO Date),“EventName”: string}
        return this.http.post(`${this.url}/infoman/searchevent`, JSON.stringify(searchEventsBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getEventCalDates(startDate: any, endDate: any){
        let Params = new HttpParams();
        Params = Params.append('startDate', startDate);
        Params = Params.append('endDate', endDate);
        return this.http.get(`${this.url}/infoman/geteventcalendar`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetEventsById(eventId: number){
        let Params = new HttpParams();
        Params = Params.append('eventId',eventId.toString());
        return this.http.get(`${this.url}/infoman/getevent`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEvents(eventsData: any){
        //For the POST => FromDate and ToDate
        return this.http.post(`${this.url}/infoman/saveevent`, JSON.stringify(eventsData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    UpdateEvents(eventsData: any){
        //For the PUT => EventDate (no more FromDate and ToDate)
        return this.http.put(`${this.url}/infoman/saveevent`, JSON.stringify(eventsData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RemoveEvents(eventId: number){
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({eventId: eventId})
        };
        return this.http.delete(`${this.url}/infoman/saveevent`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLinkedInTokenLink(){
        return this.http.get(`${this.url}/infoman/LinkedInGenerateTokenLink`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetInstagramInitLink(){
        return this.http.get(`${this.url}/infoman/InstagramGenerateTokenLink`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetMarketWeServe(marketId: number){
        let Params = new HttpParams();
        if(marketId){
            Params = Params.append('id', marketId.toString());
        }
        return this.http.get(`${this.url}/infoman/getmarketweserve`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetMarketWeServeChannel(){
        return this.http.get(`${this.url}/infoman/getmarketweserve_channel`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteMarketWeServe(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/infoman/marketweserve`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/infoman/marketweserve`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    MarketWeServeSearchTrending(submitData: any){
        return this.http.post(`${this.url}/infoman/marketweserve_search_trending`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    MarketWeServePreview(submitData: any){
        return this.http.post(`${this.url}/infoman/marketweserve_preview`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllMarketWeServerSampleReports(){
        return this.http.get(`${this.url}/infoman/marketweserve_sample_reports`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetSingleCMSReport(fileId: string){
        let Params = new HttpParams();
        Params = Params.append('file', fileId );
        return this.http.get(`${this.url}/infoman/getCMSReport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRelatedCMSReport(articleId: number){
        let Params = new HttpParams();
        Params = Params.append('id', articleId.toString() );
        return this.http.get(`${this.url}/infoman/getRelatedReports`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchHeadlines(query: string){
        let Params = new HttpParams();
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/infoman/searchheadline`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SearchContentMetrics(startPublishDate?: string, endPublishDate?: string, iMV?: { id: number, name: string }[], iAMV?: { id: number, name: string }[], oMV?: { id: number, name: string }[]
        , editor?: { id: number, name: string }, xDocs?: {id: number, title: string}[]){
        const body = new FormData();
        if(startPublishDate){
            body.append('startPublishDate', startPublishDate);
        }
        if(endPublishDate){
            body.append('endPublishDate', endPublishDate);
        }
        if(iMV && iMV.length){
            body.append('iMV', iMV.map(item=>item.id).join());
        }
        if(iAMV && iAMV.length){
            body.append('iAMV', iAMV.map(item=>item.id).join());
        }
        if(oMV && oMV.length){
            body.append('oMV', oMV.map(item=>item.id).join());
        }
        if(editor){
            body.append('editor', editor.id.toString());
        }
        if(xDocs && xDocs.length){
            body.append('xDocs', xDocs.map(item=>item.id).join());
        }
        return this.http.post(`${this.url}/infoman/content_metrics`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetContentMetricsType(xdocs: number[], type:string){
        const body = new FormData();
        body.append('xdocs', xdocs.join());
        body.append('type', type);
        return this.http.post(`${this.url}/infoman/content_metrics_types`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            map((resp: any) => { if (resp.content_metrics_types.data && type) {
                resp.content_metrics_types.type = +type;
             }; return resp; }),
            catchError(this.headerSrv._errorHandler));
    }
    GetBIActionTakenList(){
        return this.http.get(`${this.url}/infoman/newsedge_bi_action`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeBi(sectionBI:1|2|3|4|6,pageNo: number, pageSize: number, headline: string, action_taken: number, sourceName: {id: string,name: string,type: number}[],
        startDate: string, endDate: string,removeBlock:boolean,autoPublishPool:boolean,sort?: string, sortOrder?: number,screenShot?: boolean,linkedIn?: boolean,nav_art_id?: string,isRefresh?:boolean){
        let Params = new HttpParams();
        Params = Params.append('v', '2');
        const body = new FormData();
        body.append('sectionBI', sectionBI.toString());
        body.append('startDate', startDate);
        body.append('endDate', endDate);
        body.append('remove_block', removeBlock.toString());
        if(typeof pageNo === 'number'){
            body.append('pageNo', pageNo.toString());
        }
        if(typeof pageSize === 'number'){
            body.append('pageSize', pageSize.toString());
        }
        if(headline){
            body.append('headline', headline);
        }
        if(sourceName){
            body.append('source', sourceName.map(src=>src.id).join());
        }
        if(typeof action_taken === 'number'){
            body.append('action_taken', action_taken.toString());
        }
        if(sort){
            body.append('sort', sort.toString());
        }
        if(typeof sortOrder === 'number'){
            body.append('sortOrder', sortOrder.toString());
        }
        if(screenShot){
            body.append('screenshot', screenShot.toString());
        }
        if(linkedIn){
            body.append('linkedin', linkedIn.toString());
        }
        if(isRefresh){
            body.append('refresh', isRefresh.toString());
        }
        if(autoPublishPool){
            body.append('autoPublishPool', autoPublishPool.toString());
        }
        if(nav_art_id){
            body.append('nav_art_id', nav_art_id);
        }
        return this.http.post(`${this.url}/infoman/newsedge_bi_table`, body, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SubmitLinkedInConvert(articleId: number){
            const body = new FormData();
            body.append('id', articleId.toString());
            return this.http.post(`${this.url}/infoman/social_to_news`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    SubmitDuplicateToDraft(articleId: number){
            const body = new FormData();
            body.append('id', articleId.toString());
            return this.http.post(`${this.url}/infoman/social_to_draft`, body, { headers: this.headerSrv.returnHeader() }).pipe(
                catchError(this.headerSrv._errorHandler));
    }
    SubmitGateKeeperAction(articleId: any,action_taken: number,editorId?:number,priority?:number,reason?:string,reasonId?:{id:number,name:string}, override?:boolean){
        // action_taken: 0: PUBLISH, 1: ASSIGN, 2: BULK ASSIGN, -1: REJECT
        let mainBody:{id?: any, action_taken: number, send_to?:number, reason?:string, priority?: number,reasonId?:number, v?:2, override?:boolean} = {action_taken:action_taken};
        // set version 2 for newer items
        mainBody.v = 2;
        if(typeof articleId === 'string'){
            mainBody.id = articleId;
        }else{
            mainBody.id = articleId.map(artIds=>artIds['_id']).join();
        }
        if((action_taken !== -1)&&editorId){
            mainBody.send_to = editorId;
        }
        if(action_taken === 1 && reason){
            mainBody.reason = reason;
        }else if(action_taken === -1){
            mainBody['reason_id'] = reasonId.id;
            if(reason){
                mainBody.reason = reason;
            }
        }else if(action_taken === 2){
            mainBody.reason = reason;
        }
        if(priority){
            mainBody.priority = priority;
        }
        if(override){
            mainBody.override = true;
        }
        return this.http.post(`${this.url}/infoman/newsedge_bi`, JSON.stringify(mainBody), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    NewsEdgeSources(){
        return this.http.get(`${this.url}/infoman/newsedge_source?v=2`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetRejectOptions(){
        return this.http.get(`${this.url}/infoman/newsedge_bi_reject_options`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    ScraperLatestNews(scraperName: string,articleId: string){
        let Params = new HttpParams();
        Params = Params.append('Name', scraperName);
        Params = Params.append('id', articleId);
        return this.http.get(`${this.url}/infoman/ScraperLatestNews`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetTrustedSource(sourceName: string){
        let Params = new HttpParams();
        Params = Params.append('Source', sourceName);
        return this.http.get(`${this.url}/infoman/TrustedSource`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostTrustedSource(sourceName: string){
        const body = new FormData();
        body.append('Source', sourceName);
        return this.http.post(`${this.url}/infoman/TrustedSource`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeBiReview(pageNo: number, pageSize: number, headline: string, action_taken: number, sourceName: {id: string,name: string,type: number}[],sort?: string, sortOrder?: number){
        let Params = new HttpParams();
        if(typeof pageNo === 'number'){
            Params = Params.append('pageNo', pageNo.toString());
        }
        if(typeof pageSize === 'number'){
            Params = Params.append('pageSize', pageSize.toString());
        }
        if(headline){
            Params = Params.append('headline', headline);
        }
        if(sourceName){
            Params = Params.append('source', sourceName.map(src=>src.id).join());
        }
        if(typeof action_taken === 'number'){
            Params = Params.append('action_taken', action_taken.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(typeof sortOrder === 'number'){
            Params = Params.append('sortOrder', sortOrder.toString());
        }
        return this.http.get(`${this.url}/infoman/newsedge_bi`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetAddCompanyData(compSearchQuery: string){
        return this.http.get(`${this.url}/infoman/addcompany?Name=${encodeURIComponent(compSearchQuery)}`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddCompanyMetaToArticleData(addCompanyJSON: {id: number,label: string, name: string,selected: boolean}){
        return this.http.post(`${this.url}/infoman/addcompany`, JSON.stringify([addCompanyJSON]), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CheckDuplicateHeadlinePreview(storyId: string) {
        const body = new FormData();
        body.append('id', storyId);
        body.append('v', '2');
        return this.http.post(`${this.url}/infoman/test_alias`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CheckDuplicatePatents(storyId: string) {
        const body = new FormData();
        body.append('id', storyId);
        body.append('v', '2');
        return this.http.post(`${this.url}/infoman/PatentNumberSearch`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetSummarizeArticle(articleId: number, StoryContent: string) {
        const body = new FormData();
        body.append('id', articleId.toString());
        body.append('StoryContent', StoryContent.toString());
        return this.http.post(`${this.url}/story/summarize`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetInitArticleExtractor(articleId: string, refresh?:boolean) {
        let Params = new HttpParams();
        Params = Params.append('id', articleId);
        if(refresh){
            Params = Params.append('refresh', 'true');
        }
        return this.http.get(`${this.url}/infoman/init_extractor`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostArticleExtractor(articleId: string, question: string) {
        const body = new FormData();
        body.append('id', articleId);
        body.append('question', question);
        return this.http.post(`${this.url}/infoman/post_extractor`,body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    SaveUpdateDeleteMyScraper(action: 'add'|'update'|'delete',id: number,title: string,sourceName:{mv_value_id:number|string,id: string,name: string,type: number}[],headline: string){
        const body = new FormData();
        if(action === 'update' || action === 'delete' ){
            body.append('id', id.toString());
            if(action === 'delete'){
                body.append('remove', 'true');
            }
        }
       body.append('Name', title);
       body.append('Headline', headline);
       body.append('MvValueId', sourceName.map(src=>src.mv_value_id).join());
        return this.http.post(`${this.url}/infoman/MyScraper`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllMySavedScraper(){
        return this.http.get(`${this.url}/infoman/MyScraper?v=2`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetChatGPTPrompts(){
        return this.http.get(`${this.url}/infoman/getChatGPTPrompts `, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetChatGPTHeadlines(PromptId: number, StoryContent: string) {
        const body = new FormData();
        body.append('PromptId', PromptId.toString());
        body.append('StoryContent', StoryContent);
        return this.http.post(`${this.url}/infoman/getChatGPTAltHeadline`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetWeeklyAggPoolReport(aboutId: number) {
        let Params = new HttpParams();
        if(aboutId !== -1){
            Params = Params.append('id', aboutId.toString());
        }
        return this.http.get(`${this.url}/infoman/weekly_agg_pool`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetKeywordAliasByEditor(editor_id?:number) {
        let Params = new HttpParams();
        if(editor_id){
            Params = Params.append('editor', editor_id.toString());
        }
        return this.http.get(`${this.url}/infoman/keyword_alias_get_keywords`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetKeywordAliasByMV(mv_id:number) {
        let Params = new HttpParams();
        Params = Params.append('mv_id', mv_id.toString());
        return this.http.get(`${this.url}/infoman/keyword_alias_get`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostKeywordAliasByMV(mv_id:number, aliasList: {id: string, status: boolean}[]) {
        let Params = new HttpParams();
        Params = Params.append('mv_id', mv_id.toString());
        let body = { alias_list: aliasList };
        return this.http.post(`${this.url}/infoman/keyword_alias_set`, JSON.stringify(body), { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostAssignKeywordToEditor(SelectedKeywordToAssign: {NewEditor: {id: number, name: string};OldEditorId: number;metaValueKeyword: {alias_count:number,keyword:string,keyword_status:null|0|1,mv_id:number};}) {
        const body = new FormData();
        body.append('NewEditorId', SelectedKeywordToAssign.NewEditor.id.toString());
        body.append('OldEditorId', SelectedKeywordToAssign.OldEditorId.toString());
        body.append('MvId', SelectedKeywordToAssign.metaValueKeyword.mv_id.toString());
        return this.http.post(`${this.url}/infoman/assign_alias`, body, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetLinkedInDraft(Pageno?:number,sort_order?: number, sort?: string, title?: string,iMv?:{id:number, name: string}[],iAMV?:{id:number, name: string}[], linkedInType?: number){
        let Params = new HttpParams();
        if(Pageno != null){
            Params = Params.append('page', Pageno.toString());
        }
        if(sort_order != null){
            Params = Params.append('sort_order', sort_order.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(title != null){
            Params = Params.append('title', title);
        }
        if(iMv != null){
            Params = Params.append('iMv', iMv.map(imv=>imv.id).join());
        }
        if(iAMV != null){
            Params = Params.append('iAMV', iAMV.map(iamv=>iamv.id).join());
        }
        if(linkedInType != null){
            Params = Params.append('ai', linkedInType.toString());
        }
        return this.http.get(`${this.url}/infoman/linkedin_draft`, {params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLinkedInStats(date:string){
        let Params = new HttpParams();
        Params = Params.append('date', date);
        return this.http.get(`${this.url}/infoman/linkedin_stats`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLegislationCategories(){
        return this.http.get(`${this.url}/infoman/legislation_draft_categories`, {headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLegislationDraft(Rowcount:number, Pageno: number, sort_order?: number, sort?: string, tempCategoryIdData?: number, title?: string, context_date?: string, context_source?: string,
        iMv?:{id:number, name: string}[],iAMV?:{id:number, name: string}[]) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        if(sort_order != null){
            Params = Params.append('sort_order', sort_order.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(tempCategoryIdData != null){
            Params = Params.append('category', tempCategoryIdData.toString());
        }
        if(title != null){
            Params = Params.append('title', title);
        }
        if(context_date != null){
            Params = Params.append('context_date', context_date);
        }
        if(context_source != null){
            Params = Params.append('context_source', context_source);
        }
        if(iMv != null){
            Params = Params.append('iMv', iMv.map(imv=>imv.id).join());
        }
        if(iAMV != null){
            Params = Params.append('iAMV', iAMV.map(iamv=>iamv.id).join());
        }
        return this.http.get(`${this.url}/infoman/legislation_draft`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetRegulationDraft(Rowcount:number, Pageno: number, sort_order?: number, sort?: string, tempCategoryIdData?: number, title?: string, context_date?: string, context_source?: string,
        iMv?:{id:number, name: string}[],iAMV?:{id:number, name: string}[]) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        if(sort_order != null){
            Params = Params.append('sort_order', sort_order.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(tempCategoryIdData != null){
            Params = Params.append('category', tempCategoryIdData.toString());
        }
        if(title != null){
            Params = Params.append('title', title);
        }
        if(context_date != null){
            Params = Params.append('context_date', context_date);
        }
        if(context_source != null){
            Params = Params.append('context_source', context_source);
        }
        if(iMv != null){
            Params = Params.append('iMv', iMv.map(imv=>imv.id).join());
        }
        if(iAMV != null){
            Params = Params.append('iAMV', iAMV.map(iamv=>iamv.id).join());
        }
        return this.http.get(`${this.url}/infoman/regulation_draft`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetDuplicateLogs(Rowcount:number, Pageno: number, sort_order?: number, sort?: string, Duplicate_title?: string, Original_title?: string) {
        let Params = new HttpParams();
        Params = Params.append('Rowcount', Rowcount.toString());
        Params = Params.append('Pageno', Pageno.toString());
        if(sort_order != null){
            Params = Params.append('sort_order', sort_order.toString());
        }
        if(sort){
            Params = Params.append('sort', sort.toString());
        }
        if(Duplicate_title != null){
            Params = Params.append('duplicate_title', Duplicate_title);
        }
        if(Original_title != null){
            Params = Params.append('original_title', Original_title);
        }
        return this.http.get(`${this.url}/infoman/duplicates`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetEditorAITags() {
        return this.http.get(`${this.url}/infoman/weekly_editor_tag_biagg_published`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetLegislationSuggestion(xdocument_id: number, meta_value_id: number){
        let Params = new HttpParams();
        Params = Params.append('xdocument_id', xdocument_id.toString());
        Params = Params.append('meta_value_id', meta_value_id.toString());
        return this.http.get(`${this.url}/infoman/legislation_mv_rule_suggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteLegislationSuggestion(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/infoman/legislation_mv_rule_suggest`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/infoman/legislation_mv_rule_suggest`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
}
