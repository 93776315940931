import { NgModule } from '@angular/core';

import { ServiceManFullAccessDirective } from './directives/smfullaccess.directive';
import { ServiceManFullAccessInternalDirective } from './directives/smfullaccessinternal.directive';
import { InfoManFullAccessDirective } from './directives/imfullaccess.directive';
import { ServiceManSuperAdminAccessDirective } from './directives/smsuperadminaccess.directive';
import { IntranetTeamsAccessDirective } from './directives/intranetTeams.directive';
import { IframeAutoHeightDirective } from './directives/iframeautoheight.directive';
@NgModule({
    declarations: [
        ServiceManFullAccessDirective,
        InfoManFullAccessDirective,
        ServiceManFullAccessInternalDirective,
        ServiceManSuperAdminAccessDirective,
        IntranetTeamsAccessDirective,
        IframeAutoHeightDirective
    ],
    exports: [
        ServiceManFullAccessDirective,
        InfoManFullAccessDirective,
        ServiceManFullAccessInternalDirective,
        ServiceManSuperAdminAccessDirective,
        IntranetTeamsAccessDirective,
        IframeAutoHeightDirective
    ]
})
export class SharedDirectivesModule { }
