import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { FooterService, CommonService } from '../../services/index';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
    imports: [ RouterModule, CommonModule, MatTooltipModule ],
    declarations: [ FooterComponent ],
    exports: [ FooterComponent ],
    providers: [FooterService, CommonService]
})

export class FooterModule {}
