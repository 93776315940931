<div class="wrapper">
    <div class="sidebar" data-background-color="white" data-active-color="info">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel">
        <ng2-slim-loading-bar class="admin-pbar"></ng2-slim-loading-bar>
        <navbar-cmp></navbar-cmp>
        <router-outlet></router-outlet>
        <!-- <div *ngIf="!isMap()"> -->
        <footer-cmp></footer-cmp>
        <!-- </div> -->
    </div>
</div>