import { Injectable } from '@angular/core';
@Injectable()
export class LoginService {
    constructor() { }
    getGreetingTime(m) {
        let g = null; // return g
        if (!m || !m.isValid()) { return; } // if we can't find a valid or filled moment, we return.
        const split_afternoon = 12 // 24hr time to split the afternoon
        const split_evening = 17 // 24hr time to split the evening
        const currentHour = parseFloat(m.format('HH'));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            g = 'afternoon';
        } else if (currentHour >= split_evening) {
            g = 'evening';
        } else {
            g = 'morning';
        }

        return g;
    }


}
