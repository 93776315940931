import { Injectable } from '@angular/core';
import { NotifyArray } from '../../shared/interfaces.index';
declare var $: any;
@Injectable()
export class NotifyService {
    constructor() { }
    notify(notifydata: NotifyArray[], timer?:number) {
        if (notifydata) {
            for (let i = 0; i < notifydata.length; i++) {
                if (!notifydata[i].icon) { notifydata[i].icon = '' };
                $.notify({
                    icon: notifydata[i].icon,
                    message: notifydata[i].message
                }, {
                        type: notifydata[i].type,
                        delay: timer?timer:1800,
                        timer: 12,
                        placement: {
                            from: 'top',
                            align: 'center'
                        }
                    });
            }
        }
    }
}
