
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/browser';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { AuthenticationService, UtilitiesService } from '../index';
import { Router } from '@angular/router';
declare let swal: any;
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private _loadingBar: SlimLoadingBarService, private authService: AuthenticationService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // start our loader here
    this._loadingBar.start();

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      // if the event is for http response
      if (event instanceof HttpResponse) {
        if (event.status === 200 && event.body.authorized === false) {
          // redirect to the login route
          // or show a modal
          this.router.navigate(['pages/login']);
          this.authService.logout();
        } else {
          if(event.body.hasOwnProperty('expiration')){
            this.authService.SaveExpirationTime(event.body.expiration);
          }
          const UserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
          if (UserInfo && event.body.cert) {
            // set logger userinfo
            // Set user information, as well as tags and further extras
            Sentry.configureScope(scope => {
              scope.setExtra(UserInfo, event.body.cert);
              scope.setTag('user_mode', 'adminv2');
              scope.setUser(event.body.cert.qUser[0]);
              // scope.clear();
            });
            if (UserInfo.Info.roles.length !== event.body.cert.roles.length) {
              this.router.navigate(['pages/login']);
              this.authService.logout();
            } else{
              UserInfo.Info = event.body.cert;
              localStorage.setItem('AdminUserInfo', JSON.stringify(UserInfo));
            }
          }
        }
        // stop our loader here
        this._loadingBar.complete();
      }

    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          // redirect to the login route
          // or show a modal
          this.router.navigate(['/404']);
        }
      }
      // if any error (not for just HttpResponse) we stop our loader bar
      this._loadingBar.complete();
    }));
  }

}
