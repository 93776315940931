import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { RoleGuardService as RoleGuard } from './services/shared/role-guard.service';
export const AppRoutes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
    {
        path: '', component: AdminLayoutComponent,
        children: [
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            {
                path: 'dataman', loadChildren: () => import('./dataman/dataman.module').then(m => m.DataManModule),
                canActivate: [RoleGuard], data: { expectedRole: ['DataManager', 'Outsourced'] }
            },
            {
                path: 'infoman', loadChildren: () => import('./infoman/infoman.module').then(m => m.InfoManModule), canActivate: [RoleGuard], data: { expectedRole: ['Infoman', 'Outsourced'] }
            },
            {
                path: 'metaman', loadChildren: () => import('./metaman/metaman.module').then(m => m.MetaManModule),
                canActivate: [RoleGuard], data: { expectedRole: ['MetaManager'] }
            },
            {
                path: 'biaggregator', loadChildren: () => import('./biaggregator/biaggregator.module').then(m => m.BIAggregatorModule),
                canActivate: [RoleGuard], data: { expectedRole: ['MetaManager~FullAccess'] }
            },
            {
                path: 'serviceman', loadChildren: () => import('./serviceman/serviceman.module').then(m => m.ServiceManModule),
                canActivate: [RoleGuard], data: { expectedRole: ['Serviceman'] }
            },
            {
                path: 'qamanager', loadChildren: () => import('./qamanager/qamanager.module').then(m => m.QAManagerModule), canActivate: [RoleGuard], data: { expectedRole: ['Infoman'] }
            },
            {
                path: 'pagemonitor', loadChildren: () => import('./pagemonitor/pagemonitor.module').then(m => m.PageMonitorModule),
                canActivate: [RoleGuard], data: { expectedRole: ['Infoman'] }
            },
        ]
    },
    { path: '401', loadChildren: () => import('./layouts/accessdenied/accessdenied.module').then(m => m.AccessDeniedModule) },
    { path: '**', loadChildren: () => import('./layouts/pagenotfound/pagenotfound.module').then(m => m.PageNotFoundModule) }

];
