import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
@Injectable()
export class UtilitiesService {
    constructor() { }
    mapOrder(arrayToReOrder, desiredOrder, keyToSortBy: string) {
        arrayToReOrder.sort((a, b) => {
            let A = a[keyToSortBy], B = b[keyToSortBy];
            if (desiredOrder.indexOf(A) > desiredOrder.indexOf(B)) {
                return 1;
            } else {
                return -1;
            }
        });
        return arrayToReOrder;
    };
    findItemInArray(array, keyName, Value) {
        const results = array.filter((singleItem) => singleItem[keyName] === Value);
        return results ? results[0] : null;
    }
    sliceArray(itemsArray: any[], index: number) {
        return [...itemsArray.slice(0, index), ...itemsArray.slice(index + 1)]
    }
    containsAll(arr1, arr2) {
        return arr2.every(arr2Item => arr1.includes(arr2Item))
    }

    sameMembers(arr1, arr2) {
        return this.containsAll(arr1, arr2) && this.containsAll(arr2, arr1);
    }

    checkStartDate(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const f = group.controls[from];
            const t = group.controls[to];
            if (!moment(f.value).isSameOrBefore(t.value)) {
                return {
                    'dates': 'Start date should be less than end date'
                };
            }
            return {};
        }
    }
    titleCase(str) {
        str = str.toLowerCase().split(' ').map((word) => {
            return word.replace(word[0], word[0].toUpperCase());
        });
        return str;
    }
    // Required Validator Function for Multiselect Dropdown in PRIMENG
    multiSelectRequired(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const selections = control.value;
            const isValid = selections.length > 0;
            const msg = 'selection required';

            return isValid ? null : { 'multiSelectRequired': { msg } };
        };
    }
    DownloadCSV(csvString: string, exportFilename: string) {
        var csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        //IE11 & Edge
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(csvData, exportFilename);
        } else {
            //In FF link must be added to DOM to be clicked
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute('download', exportFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    DateChecker(datesArray: any[]) {
        const firstItem: object = datesArray[0];
        let DateValues = Object.keys(firstItem).filter(key => isDate(firstItem[key]))
        if (DateValues && DateValues.length) {
            for (let i = 0; i < datesArray.length; i++) {
                Object.keys(datesArray[i]).map(key => {
                    if (key === DateValues[0]) {
                        datesArray[i][key] = moment(datesArray[i][key]).utc().format('MM-DD-YYYY');
                    }
                })
            }
            return datesArray;
        }
        function isDate(_date) {
            const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
            return _regExp.test(_date);
        }
    }
    removeDuplicates(array, key) {
        return array.filter((obj, index, self) =>
            index === self.findIndex((el) => (
                el[key] === obj[key]
            ))
        )
    }
    IsObjectEmpty(object) {
        if (Object.keys(object).length === 0) {
            return false;
        } else {
            return true;
        };
    }
    /**
     * CHECKS IF OBJECT IS EMPTY
     * @return true if object is empty
     */
    IsObjectEmpty2(obj) {
        // IF EMPTY RETURN TRUE
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    isObject(obj) {
        return obj != null && obj.constructor.name === "Object"
    }
    ObjectToUrlParameter(obj) {
        let str = "";
        for (let key in obj) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(obj[key]);
        }
        return str ? str : null;
    }
    StingNumbersToArray(stringNumbers: string): number[] {
        return stringNumbers.split(",").map(x => +x);
    }
    /*
     * Returns a deep copy of the object
    */
    deepCopy(oldObj: any) {
        var newObj = oldObj;
        if (oldObj && typeof oldObj === "object") {
            if (oldObj instanceof Date) {
                return new Date(oldObj.getTime());
            }
            newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
            for (var i in oldObj) {
                newObj[i] = this.deepCopy(oldObj[i]);
            }
        }
        return newObj;
    }
    groupBy(arr, property) {
        /*
        var o = groupBy(arr, 'type'); // => {orange:[...], banana:[...]}
            o.orange; // => [{"type":"orange","title":"First"},{"type":"orange","title":"Second"}]
            o.banana; // => [{"type":"banana","title":"Third"},{"type":"banana","title":"Fourth"}]
        */
        return arr.reduce(function (memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }
    /**
    * generate groups of 4 random characters
    * @example getUniqueId(1) : 607f
    * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
    */
    getUniqueId(parts: number): string {
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }
    ReformatImages(dt) {
        let imgTemp = [];
        let imgTemp2 = [];
        let retVal = dt;
        if (dt.length > 1) {
            for (let i = 0; i < dt.length; i++) {
                if (i % 2) {
                    imgTemp.push(dt[i])
                } else {
                    imgTemp2.push(dt[i])
                }
            }
            if (imgTemp2.length !== imgTemp.length) {
                if (imgTemp2.length > imgTemp.length) {
                    imgTemp.push({ id: -1, url: '', title: ''});
                } else if (imgTemp.length > imgTemp2.length) {
                    imgTemp2.push({ id: -1, url: '', title: '' });
                }
            }
            retVal = [];
            for (let k = 0; k < imgTemp2.length; k++) {
                if (imgTemp2[k]) {
                    let imagesObj = {
                    id:imgTemp2[k].id,
                    url:imgTemp2[k].url,
                    title:imgTemp2[k].title,
                    id2:imgTemp[k].id,
                    url2:imgTemp[k].url,
                    title2:imgTemp[k].title,
                    }
                    retVal.push(imagesObj)
                }
            }
        }
        return retVal
    }
}
