import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthenticationService } from '../index';
declare var swal: any;
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.auth.isAuthenticated()) {
      // this will be passed from the route config
      // on the data property
      const expectedRole = route.data.expectedRole;
      // const excludeRole = route.data.excludeRole;
      const adminUserInfo = JSON.parse(localStorage.getItem('AdminUserInfo'));
      if (adminUserInfo) {
       const compareUserRoles = adminUserInfo.Info.roles.filter(userRoles => expectedRole.includes(userRoles));
       const allowEntry: boolean = compareUserRoles&&compareUserRoles.length?true:false;
      //  let doNotAllowEntry: boolean = adminUserInfo.Info.roles.includes(excludeRole);
        if (allowEntry) {
          return true;
        } else {
          this.router.navigate(['401']);
          return false;
        }
      } else {
        swal({
          title: 'Access Denied!',
          text: 'Hmm. Seem like you do not have access to view this!',
          type: 'error',
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Oh wow!',
        })
        return false;
      }
    } else {
      this.router.navigate(['pages/login']);
      return false;
    }
  }
}
