declare function require(moduleName: string): any;
export const environment = {
  mode: 'prod',
  urlpath: 'https://api.industryintel.com/api',
  production: true,
  verAdm: require('../../package.json').version,
  version: 'version.json',
  pusher: {
    key: '63df9b6b6e2a0e2b1b64',
    cluster: 'us3'
  },
  msClientID: "516431b0-614f-4d1e-9283-7734f88d4db5",
  msTenantId: "dda4d46e-0f10-443e-92bd-7b500b4f9e7f",
  msAuthority: 'https://login.microsoftonline.com/dda4d46e-0f10-443e-92bd-7b500b4f9e7f',
  msRedirectURI: `https://adm.industryintel.com/dashboard?msredirect=true`
};
