
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable()
export class CommonService {
    constructor(private http: HttpClient) { }
    getGreetingTime(m) {
        let g = null; // return g
        if (!m || !m.isValid()) { return; } // if we can't find a valid or filled moment, we return.
        const split_afternoon = 12 // 24hr time to split the afternoon
        const split_evening = 17 // 24hr time to split the evening
        const currentHour = parseFloat(m.format('HH'));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            g = 'afternoon';
        } else if (currentHour >= split_evening) {
            g = 'evening';
        } else {
            g = 'morning';
        }

        return g;
    }
    getPageNotFoundGiphy() {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`https://api.giphy.com/v1/gifs?api_key=Go5LhoDsTlgb8rWCUiRcDIMKiMZLQARp&ids=14uQ3cOFteDaU, NTXqH1bUCfHBS`).pipe(
            catchError(this._errorHandler))
    }
    getPageNotFoundRandomGiphy() {
        return this.http.get(`https://api.giphy.com/v1/gifs/random?api_key=Go5LhoDsTlgb8rWCUiRcDIMKiMZLQARp&tag=oops&rating=G`).pipe(
            catchError(this._errorHandler))
    }
    getRandomGiphyByTag(tags: string) {
        return this.http.get(`https://api.giphy.com/v1/gifs/random?api_key=Go5LhoDsTlgb8rWCUiRcDIMKiMZLQARp&tag=${tags}&rating=G`).pipe(
            catchError(this._errorHandler))
    }
    getGiphyById(id: string) {
        return this.http.get(`https://api.giphy.com/v1/gifs/${id}?api_key=Go5LhoDsTlgb8rWCUiRcDIMKiMZLQARp&rating=G`).pipe(
            catchError(this._errorHandler))
    }
    _errorHandler(error: Response) {
        return observableThrowError(error || 'Server Error on Server Occured');
    }

}
